import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/toimintasuunnitelma.css';
import '../css/yhdistys.css';
import {Link} from 'react-router-dom';

class ToimintaSuunnitelma extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "theplan.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      		this.setState({ content: data })
      		//console.log(this.state.content)
     	})
	}
		
	buildToimintaSuunnitelma(content) {
		
		return (
			<div key={content.id} className="toimintasuunnitelma">
				<Link to="/calendar">Katso kalenteri</Link>
				<br />
				<Link to="/toimintakertomukset">Aiempien vuosien toimintakertomukset</Link>
				<h3>Toimintasuunnitelma {content.vuosi}</h3>
				<img src={content.image1} alt="kuva1" className="content-image"/>
				<p>{content.toimintasuunnitelma}</p>
				<img src={content.image2} alt="kuva2" className="content-image"/>
				<p>{content.toimintasuunnitelma2}</p>
			</div>
		
		);
		}
		
	render() {
		
		return (
		<div className="toimintasuunnitelma-container">
			<div className="toimintasuunnitelma-wrapper">
      			{this.state.content.map(content => this.buildToimintaSuunnitelma(content))}
			</div>
		</div>		
		);
	}
}
		
export default ToimintaSuunnitelma;

