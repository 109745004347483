import axios from 'axios';


const axiosConfig = axios.create({
	
	baseURL:"https://ptmks.fi/php/" 
	
	
});



export default axiosConfig;