import React from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import '../../../css/liftup.css';
import {Link} from 'react-router-dom';


const formdata = new FormData();
const formdata2 = new FormData();


class NonvalidatedReservations extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
			selectedOption:"",

		}


	};

	 

	componentDidMount() {
		const url = "check_nonvalidated_reservations.php"
		formdata2.append("creds", localStorage.getItem("user"));
		formdata2.append("contact_id", localStorage.getItem("user_contact_id"));
		formdata2.append("type", localStorage.getItem("user_type"));
		axiosConfig.post(url, formdata2).then(response => response.data).then((data) => {
      this.setState({ content: data })
      console.log(this.state.content);
     })

		
	}


	handleInputChange = (event) => {

		const target = event.target;
		const name = target.name;
		const id = target.id;

		if(formdata.get(id)) {
			formdata.delete(id);
		}
		else {
			formdata.append(id, name);
			
		}
		//console.log(formdata);

	}

	handleSubmit = async (event) => {
		if(window.confirm("Olet vahvistamassa varauksen. Vahvistuksen jälkeen asiakkaalle lähetetään lasku. Tätä toimintoa ei voi peruuttaa. Haluatko jatkaa?")) {
		event.preventDefault();
		const url = "validate_reservations.php";


		const { data } = await axiosConfig.post(url, formdata, {
    	})

    	//console.log(data);
    	window.location.reload();
    
    }
	}


		
	buildReservationInfo(reservation) {
		return (
			
			<tr key={reservation[1].res_ref}>
			<td><Link to={{ pathname: '/show_reservations/' + reservation[1].res_ref  }} state={{ res_ref: reservation[1].res_ref, product: reservation[0].nimi }}>{reservation[1].res_ref}</Link>
			</td>
			<td>{reservation[0].nimi}</td>
			<td className="hide-cell-mobile">{reservation[1].first_name}</td>
			<td className="hide-cell-mobile">{reservation[1].last_name}</td>
			<td className="hide-cell-mobile">{reservation[1].phone}</td>
			<td className="hide-cell-mobile">{reservation[1].email}</td>
			<td><input 
				type="checkbox" 
				name={reservation[1].res_ref}
				id={reservation[1].res_ref}
				onChange={this.handleInputChange}/></td>

			</tr>
			
			)
	
	}

	buildReservationList(content) {
		if(typeof content !== "undefined" && content.length > 0) {
		return(
			content.map(reservation => this.buildReservationInfo(reservation))
			)
		}
	}

	render() {
		let content = this.state.content;
		if(content.length > 0) {
			
			return (
			<div className="reservation-listing-container reservation-fonts">
			<Link to="/contentmanagement"  className="return-link">Takaisin hallintapaneeliin</Link>
			<h1>Vahvistamattomat varaukset:</h1>
			
			<form onSubmit={this.handleSubmit}>
			<table>
			<tbody>
			<tr>
				<th>Varausnumero:</th>
				<th>Tuote:</th>
				<th className="hide-cell-mobile">Asiakkaan etunimi:</th>
				<th className="hide-cell-mobile">Asiakkaan sukunimi:</th>
				<th className="hide-cell-mobile" >Puhelin:</th>
				<th className="hide-cell-mobile">Email:</th>
				<th>Valitse:</th>
			</tr>
      			{this.buildReservationList(content)}
      		</tbody>
      		</table>
      		<br />
      		<input type="submit" value="Vahvista valitut" className="return-link"/>
      		</form>
			</div>
		);
		}
		else {
			return (
				<div className="reservation-listing-container">
				<Link to="/contentmanagement">Takaisin hallintapaneeliin</Link>
				<p>Tällä hetkellä ei ole vahvistamattomia varauksia</p>
				</div>
				)
		}

		
	}
}
		
export default NonvalidatedReservations;