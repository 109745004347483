import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/yhdistys.css'
import {Link} from 'react-router-dom'
import EUlogo from '../logos/Lippu.jpg'
import Leader from '../logos/Leader.jpg'
import LounaPlussa from '../logos/LounaPlussa.jpg'

class Project extends React.Component { 


		
	render() {
		
		return (
		<div className="projectfunding-container">
		<div className="project-logos">
				<img src={EUlogo} alt="logo" width="200px" height="50px"/>
				<img src={Leader} alt="logo" width="100px" height="50px" />
				<img src={LounaPlussa} alt="logo" width="200px" height="50px"/>
			</div>
			<div className="projectfunding-wrapper">
				<br /> 
				<br />
      			<p>Yhdistyksen nettisivujen ja varausjärjestelmän hankinta on saanut rahoitusta Maaseuturahastosta LounaPlussan kautta. 

      			<br />
      			<br />
      			Projektin kokonaiskustannukset olivat 7625 euroa, josta julkinen tuki 6100 euroa.</p>
			</div>
		</div>		
		);
	}
}
		
export default Project;