import React from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import '../../../css/reservations.css';
import {Link} from 'react-router-dom';


const formdata = new FormData();


class DeleteProducts extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
			response: '',
		}


	};

	 

	componentDidMount() {
		const formdata2 = new FormData();
		let url = "product_query.php";
		
		formdata2.append("contact_id", localStorage.getItem("user_contact_id"));
		formdata2.append("creds", localStorage.getItem("user"));
		formdata2.append("type", localStorage.getItem("user_type"));
		axiosConfig.post(url, formdata2).then(response => response.data).then((data) => {
      this.setState({ content: data })
      console.log(data);
      //console.log(this.state.content);
     })
	}

	

	handleNotInUse = async (event, value, type) => {
		const formdata2 = new FormData();
		
			if(window.confirm(value === "0" ? "Olet poistamassa kohteen käytöstä. Kohdetta ei tämän jälkeen listata varattavaksi. Toiminnon voi perua myöhemmin. Haluatko jatkaa?":"Olet ottamassa kohteen takaisin käyttöön. Kohde listataan jatkossa varattavaksi. Toiminnon voi perua myöhemmin. Haluatko jatkaa?")) {
				event.preventDefault();
				const url = "change_product_not_in_use.php";


				formdata2.append("id", event.target.id);
				formdata2.append("name", event.target.name);
				formdata2.append("type", type);
				formdata2.append("not_in_use", value);
				formdata2.append("creds", localStorage.getItem("user"));
				//console.log(formdata2);
				const { data } = await axiosConfig.post(url, formdata2, {
    			})
    			this.setState({content: data});
    		
			}
		

	}

	handleInputChange = (event) => {

		
		if(formdata.get(event.target.name)) {
			formdata.delete(event.target.name);
		}
		else {
			let input = [JSON.stringify({type: event.target.value, id: event.target.id})];
			
			formdata.append(event.target.id, input);
			
		}
		//console.log(formdata);

	}

	handleSubmit = async (event) => {
		if(window.confirm("Olet poistamassa tuotteen järjestelmästä. Toimintoa ei voi peruuttaa. Haluatko jatkaa?")) {
		event.preventDefault();
		const url = "delete_products.php";


		const { data } = await axiosConfig.post(url, formdata, {
    	})
    	this.setState({response: data});
    	window.location.reload();
    
    	}
	}

	buildNotInUseButton(product) {
		let value = product.not_in_use;
		let type = product.type;
		if(localStorage.getItem('user') === "is_super" || localStorage.getItem('user') === "is_admin") {
		return (<input 
				type="button" 
				name={product.nimi}
				id={product.id}
				value={product.not_in_use === "0" ? "Poista käytöstä":"Ota käyttöön"} 
				onClick={(e) => this.handleNotInUse(e, value, type)}/>
			)
		}
	}

	buildCheckBox(product) {
		if(localStorage.getItem('user') === "is_super" || localStorage.getItem('user') === "is_admin") {
		if(product.reservations_exist === false) {
			return (
				<input 
				type="checkbox" 
				name={product.nimi}
				id={product.id}
				value={product.type} 
				onChange={this.handleInputChange}/>
			)
		}
		}
	}

	buildUpdateLink(product) {
		let osoite = "";
		if(typeof product.osoite !== "undefined") {
			osoite = product.osoite;
		}
		if(localStorage.getItem('user') === "is_super" || localStorage.getItem('user') === "is_admin") {
		
			return (
				<Link to="/update_product_data" 
					  state={{ 
					  	name: product.nimi, 
					  	type: product.type, 
					  	id: product.id, 
					  	imagefilename: product.imagefilename, 
					  	link: product.link, 
					  	contact_id: product.contact_id, 
					  	hinta: product.hinta, 
					  	hintaperuste: product.hintaperuste, 
					  	hinta2: product.hinta2, 
					  	hintaperuste2: product.hintaperuste2, 
					  	muuta: product.muuta, 
					  	menu_weight: product.menu_weight,
					  	osoite: osoite 
					  }}>Muuta tietoja</Link>
			)
		
	}}

		
	buildProductInfo(product) {
		
		return (
			
			<tr key={product.nimi}>
			<td>{product.nimi}</td>
			<td className="hide-cell-mobile">{product.hinta}</td>
			<td className="hide-cell-mobile">{product.hintaperuste}</td>
			<td className="hide-cell-mobile">{product.hinta2}</td>
			<td className="hide-cell-mobile">{product.hintaperuste2}</td>
			<td>{this.buildUpdateLink(product)}</td>
			<td>
			{this.buildNotInUseButton(product)}
			</td>
			<td>
			{this.buildCheckBox(product)}	
			</td>
			</tr>
			
			)
	
	}

	buildProductList(content) {
		if(content.length > 0) {
			return(
				content.map(product => this.buildProductInfo(product))
				)
		}
		else {
			return (
				<p>Tällä hetkellä ei ole tuotteita</p>
				)
		}	
	}

	buildAdminHeaders() {
		if(localStorage.getItem('user') === "is_super" || localStorage.getItem('user') === "is_admin") {
		
			return (
				<>
				<th>Muuta tietoja:</th>
				<th>Poista käytöstä:</th>
				<th>Poista järjestelmästä:</th>
				</>
			)
		
		}
	}

	render() {
		let content = this.state.content;
		if(content.length !== 0) {
			
			return (
			<div className="reservation-listing-container reservation-fonts">
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>
			<form onSubmit={this.handleSubmit} className="reservation-data-table">
			<table>
			<tbody>
			<tr>
				
				<th>Tuote:</th>
				<th className="hide-cell-mobile">Hinta:</th>
				<th className="hide-cell-mobile">Hintaperuste:</th>
				<th className="hide-cell-mobile">Hinta2:</th>
				<th className="hide-cell-mobile">Hintaperuste2:</th>
				{this.buildAdminHeaders()}
			</tr>
      			{this.buildProductList(content)}
      		</tbody>
      		</table>
      		<br />
      		<input type="submit" value="Poista valitut" className="return-link"/>
      		</form>
			</div>
		);
		}
		else {
			return (
				<div className="reservation-listing-container">
				<Link to="/contentmanagement">Takaisin hallintapaneeliin</Link>
				<p>Tällä hetkellä ei ole kohteita</p>
				</div>
				)
		}

		
	}
}
		
export default DeleteProducts;