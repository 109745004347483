import {Link, useNavigate} from 'react-router-dom';
import '../css/links.css';
import Login from './login.js';

function ContentManagement() {
	if(localStorage.getItem('user')) {
		return (
			<>
			<h2 className="mobilelink-header">Sisällönhallinta</h2>
			<Link to="/contentmanagement" className="link">Sisällönhallinta</Link>
			</>
		);
	}
}

function MobileLinks() {

	const navigate = useNavigate();
	const goBack = (e) => {
		e.preventDefault();
		navigate(-1);
	}

  return (

	<div>
	<Link to="/" className="mobileclose" onClick={goBack}>&#10005;</Link>
			<div className="mobilelinkbar">	
				
	  		<div className="mobilelink-wrapper">
	  		<Link to="/news" className="link">Ajankohtaista</Link>
	  		
	  		
	  		<h2 className="mobilelink-header">Yhdistys</h2>
	  		
	  			<Link to="/description" className="link">Kuvaus</Link><br/> 
	  			<Link to="/galleria" className="link">Galleria</Link><br/>
	  			<Link to="/liity" className="link">Liity jäseneksi</Link><br/>
	  			<Link to="/theplan" className="link">Toimintasuunnitelma</Link><br/> 
	  			<Link to="/calendar" className="link">Kalenteri</Link><br/>
	  			<Link to="/contacts" className="link">Yhteystiedot</Link>
	  		 
      	<h2 className="mobilelink-header">Palvelut</h2>
					<Link to="/machines" className="link">Vuokrattavat koneet</Link><br />
					<Link to="/vuokrattavat_tarvikkeet" className="link">Vuokrattavat tarvikkeet</Link><br />
					<Link to="/services" className="link">Jäsenten tarjoamat palvelut</Link>
				
				{/*
				<h2 className="mobilelink-header">Teuro-Kuuslammin kyläyhdistys</h2>
							<Link to="/muut_tuotteet" className="link">Vuokrattavat kohteet</Link><br />
				*/}			
				


					{ContentManagement()} <br />
				
				
				<Login />
				<br />
				<br />
				<br />
				</div>

			</div>


			
	</div>
	); 
}

export default MobileLinks;