import {React, useState, useEffect} from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import {Link} from 'react-router-dom';

function AddUserForm() { 

  	const [contactInfo, setContactInfo] = useState({
    	firstname: "",
    	lastname: "",
    	email: "",
    	phone: ""

  	});

  	const [response, setResponse] = useState(""); 
  	const [type, setType] = useState(localStorage.getItem("user_type"));
  	const [userCreds, setUserCreds] = useState();

  	const [contactList, setContactList] = useState([]);

  	const [selectedOption, setSelectedOption] = useState('option1')
  	

  	const [userInfo, setUserInfo] = useState({
  		username:"",
  		password:"",
  		contactId:"",
  	})


  	

  	const getContacts = async (type) => {
  		
  		let formData2 = new FormData();
  		formData2.append("type", type);

  		const url = "create_user_contacts.php"
		const { data } = await axiosConfig.post(url, formData2);
  		setContactList(data);
  		//console.log(data);

  	};

  	useEffect(() => {
		if(localStorage.getItem("user") !== "is_admin") {
    		if(Object.keys(contactList).length === 0) {
    			getContacts(type);
    		}
    	}
    	
    	
  	});

  	const sendAddUser = async (e) => {
  		if(window.confirm("Olet lisäämässä uuden käyttäjän. Haluatko jatkaa?")) {
  		e.preventDefault();
  		

  		const url = "add_user.php";
  		let formData = new FormData();
  		
  		
  		formData.append("creds", userCreds);
		formData.append("type", type);
		formData.append("username", userInfo.username);
		formData.append("password", userInfo.password);



  		if(selectedOption === "option1") {
  		
  			formData.append("contact_id", userInfo.contactId);
  		}
  		else {

  		Object.keys(contactInfo).forEach(key => {
  			//console.log(contactInfo[key]);
  			formData.append(key, contactInfo[key]);
  		} 
  		);

  		}

  		
  		

		console.log(formData);
		const { data } = await axiosConfig.post(url, formData, {});
		if(data === "Käyttäjätunnus on jo olemassa") {
			setResponse(data);
		}
		if(data === true) {
			setResponse("Olet luonut uuden käyttäjätunnuksen. Käyttäjätunnus: " +userInfo.username + " Salasana: " +userInfo.password);
		}
		//console.log(data);
		if(data === false) {
			setResponse("Jotain meni pieleen. Yritä uudelleen.");
		}
		  	
	}
  	}

  	const userRoles = () => {
  		let user = localStorage.getItem("user");

  		if(user === "is_admin") {
  			return (
  				<select id="userCreds" 
  						name="userCreds"
  						value={userCreds} 
  						onChange={(e) => setUserCreds(e.target.value)} required>
  						<option value="">Valitse:</option>
  						<option value="is_admin">Admin</option>
  						<option value="is_super">Pääkäyttäjä</option>	
						<option value="is_updater">Päivittäjä</option>
  				</select>
  			);
  		}
  		else {
  			return (
  				<select id="userCreds" 
  						name="userCreds"
  						value={userCreds} 
  						onChange={(e) => setUserCreds(e.target.value)} required>
  						<option value="">Valitse:</option>
  						<option value="is_super">Pääkäyttäjä</option>	
						<option value="is_updater">Päivittäjä</option>
  				</select>
  				);
  		}
  	}


  	const createContactList = (contactList) => {
  		if(contactList.length > 0) {
  		return (
  		contactList.map(contact => 
  			<option value={contact.id} key={contact.id} id={contact.id}>{contact.etunimi} {contact.sukunimi}, {contact.puhelin}, {contact.email}</option>
  			)
  		);
  		}
  	}


  	

  	const handleChange = (event) => {
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  	};

  	const handleUserChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  	};


  	const setTheType = (e) => {
  		setType(e.target.value);
  		//console.log(e.target.value);
  		if(e.target.value !== "") {
  		getContacts(e.target.value);
  		//console.log(contactList);
  		}
  	}

  	const setThisType = () => {
  		if(localStorage.getItem("user") === "is_admin") {
  			return (
  			<div>
  			<label>Seura:</label><br />	
  			<select id="type" 
  						name="type" 
  						onChange={setTheType} required>
  						<option value="">Valitse:</option>
  						<option value="0">Maa- ja kotitalousseura</option>
  						<option value="1">Kotitalousnaiset</option>	
						<option value="2">Kyläyhdistys</option>
  			</select>
  			</div>
  			)
  		}
  	}

  	
  	
	
	function buildReservationForm(props) {


		let contactContent;
  		if(selectedOption ==="option1") {
  			contactContent = (
  				<div><select onChange={handleUserChange} value={userInfo.contactId} name="contactId" required>
  				<option value="">Valitse:</option>
  				{createContactList(contactList)}</select></div>
  			);
  		}
  		else {
  			contactContent = (
  				<div>
  				<label>Etunimi:</label><br />
				<input className="management-input"  type="text" name="firstname"  onChange={handleChange} required></input><br />
				<br />
				<label>Sukunimi:</label><br />
				<input className="management-input"  type="text" name="lastname"  onChange={handleChange} required></input><br />
				<br />
				<label>Email:</label><br />
				<input className="management-input"  type="text" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" onChange={handleChange} required></input><br />
				<br />
				<label>Puhelin:</label><br />
				<input className="management-input"  type="text" name="phone"  onChange={handleChange} required></input><br />

  				</div>
  			);
  		}


		return (
			<div className="reservation-fonts">
			<br /> 
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link><br /><br />
			<h1>Lisää käyttäjä:</h1>
			
			<div>
			{setThisType()}
			</div>
			<div className="reservation-form">
			
				<form onSubmit={sendAddUser}>
				<br />
				{response}
				<br />
				<br />
				<label>Käyttäjänimi:</label><br />
				<input className="management-input" type="text" name="username"  value={userInfo.username} onChange={handleUserChange} required></input>
				<br />
				<label>Salasana:</label><br />
				<input className="management-input" type="text" name="password" value={userInfo.password} onChange={handleUserChange} required></input>
				
				<br />
				<label>Käyttäjän rooli:</label><br />

				
				{userRoles()}
				
				<br />
				<br />
				<br />

				<div className="radio">
      			<label>
        			<input type="radio" 
        				   value="option1"
        				   id="option1"
        				   checked={selectedOption === 'option1'} 
        				   onChange={(e) => setSelectedOption(e.target.value)}/>
       				Käytä olemassaolevia yhteystietoja:

      			</label>
    		</div>
    		<br />
    		<br />
    		<div className="radio">
      			<label>
        			<input type="radio" 
        				   value="option2"
        				   id="option2" 
        				   checked={selectedOption === 'option2'}
        				   onChange={(e) => setSelectedOption(e.target.value)}/>
        			Syötä uudet yhteystiedot:
      			</label>
    		</div>
    		<br />
    		Yhteystiedot:
    		{contactContent}
    		<br />
    		<br />
				<input type="submit" value="Luo käyttäjä" className="return-link"></input>

				</form>
			</div>
			</div>
		);
		}
		
	
		return (
		<div className="yhdistys-container">
			<div className="yhdistys-wrapper">
      			{buildReservationForm()}
			</div>
		</div>		
		);
	
}
		
export default AddUserForm;