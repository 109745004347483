
import React, { useState, useEffect } from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/reservations.css';
import '../css/reservation_calendar.css'
import Calendar from 'react-calendar';
import Time from './calendar/time';
import {formatDate, helpButton} from './helperFunctions';
import {useParams, useLocation} from 'react-router-dom';
import ReservationForm from './reservation_form';


function ReservationCalendar() {
	const location = useLocation();
	const { name, type } = location.state;
	const { id } = useParams();
	const [thisDate, setDate] = useState(new Date());
	const [showTime, setShowTime] = useState(false);
	const [reservations, setReservations] = useState([]);
	const [times, setTimes] = useState();
	const [reservation, setReservation] = useState([]);
	const [showForm, setShowForm] = useState(false);
	const [showHelp, setShowHelp] = useState(false);

	//console.log(reservationList);
	function tileClassName({ date, view }) {
		let className = 'white-all-free';
		let timescounter = 0;
		for(let i = 0; i < Object.keys(reservations).length; i++) {

			if(reservations[i].res_date === formatDate(date)) {
				timescounter = timescounter + reservations[i].times.length;
			}


			if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use !== '1' && (timescounter > 0 || reservations[i].times.length > 0)) {
				className = 'yellow-partial-reserved';

			}
			else if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use === '1') {
				className = 'grey-out-of-order';
			}
			if((reservations[i].res_date === formatDate(date) 
				&& reservations[i].times.length === 6) || timescounter === 6) {
				className = 'red-all-reserved';
			}
			
		}
		for(let j = 0; j < Object.keys(reservation).length; j++) {
			
			if(reservation[j].get("Date") === formatDate(date)) {
				className = "green-chosen-time";
			} 
			
		}
		const thisdate = new Date();
		let newDate = formatDate(thisdate);
		

		if(formatDate(date) < newDate) {
			className = "grey-out-of-order";
		}

		//console.log(timescounter);
		return className;
		
  	}

  	
  	function tileDisabled({ date, view }) {
  		for(let i = 0; i < Object.keys(reservations).length; i++) {
			if(reservations[i].res_date === formatDate(date) 
			&& reservations[i].not_in_use === '1'
			) 	{
			return true;
			}
			if(reservations[i].res_date === formatDate(date) 
				&& reservations[i].times.length === 6) {
				return true;
			} 
		}
		const thisdate = new Date();
		let newDate = formatDate(thisdate);
		

		if(formatDate(date) < newDate) {
			return true;
		}

  		
  	}
  	/*
  	const helpButton = () => {
  		return (
  			<button className="help-button" />
  			
  			)
  	}
  	*/

 
  	const onDateChange = (newDate) => {
  		setShowTime(true);
		setDate(newDate);


		let returnvalue = [];
  		for(let i = 0; i < Object.keys(reservations).length; i++) {
  			
			if(reservations[i].res_date === formatDate(newDate) && Object.keys(reservations[i].times).length > 0) {
				returnvalue = returnvalue.concat(reservations[i].times.map(time => time));
				//setTimes(returnvalue);

			}
			else {
				//setTimes(returnvalue);
			}
		}
		setTimes(returnvalue);
	}
 
  	const onHelpClick = () => {
  		setShowHelp(true);

  	}

  	const helpPanel = () => {
  		if(showHelp === true) {
  			
  			return(
  				<div className="help-panel" id="help-panel">
  				<button onClick={(e) => setShowHelp(false)} className="help-panel-close">X</button>
  				
  				</div>

  			)
  			
  		}
  		else {
  			return (
  				<div className="help-panel">{helpButton(onHelpClick)}</div>
  				)
  		}
  	}

  	const helpPanelContent = () => {
  		if(showHelp === true) {
  			return (
  				<div className="help-panel-content">
  				<p>Värien selitykset:</p>
  				<p><span className="white block"></span> Valkoinen: Päivä on kokonaan vapaa</p> 
  				<p><span className="green block"></span> Vihreä: Olet lisännyt päivän varaukseesi</p>
  				<p><span className="blue block"></span> Sininen: Päivä joka on nyt valittuna</p>   
  				<p><span className="yellow block"></span> Keltainen: Päivälle on vielä vapaita aikoja</p> 
  				<p><span className="red block"></span> Punainen: Kaikki ajat on varattu</p> 
  				<p><span className="grey block"></span> Harmaa: Päivä on jo mennyt tai kone ei ole käytössä</p> 
  				<p><span className="darkgrey block"></span> Tummanharmaa: Kursori on päivän kohdalla</p> 
  				</div>
  			)
  		}
  	}

	const getReservations = async () => {
  		const url = "check_reservations.php"
  		const formData =  new FormData();
  		formData.append('id', id);
  		formData.append('type', type);
  		const { data } = await axiosConfig.post(url, formData, {
    	})
    	//console.log(data);
    	setReservations(data);
    	
	};

	useEffect(() => {
		if(Object.keys(reservations).length === 0) {
    		getReservations();
    		
    	}
    	
    	setDate(thisDate);
    	
    	
  	}, [thisDate, reservation]);
	
	

	if(showForm === false) {
	
	return (
	<div className="calendar-container">
    	<div >
    	<div className="reservation-calendar-header">
    		<h1 className="reservation-form-headers">Varauskalenteri: <br />{name}</h1>
      		
      		{helpPanel()}
      		</div>
      		{helpPanelContent()}
      		<Calendar 
      		onChange={onDateChange} 
      		value={thisDate} 
      		tileClassName={tileClassName} 
      		tileDisabled={tileDisabled} />
    	</div>
    	
        <Time showTime={showTime} 
        	  date={thisDate} 
        	  times={times} 
        	  setReservation={setReservation} 
        	  reservation={reservation} 
        	  />
        	  <div className="finish-reservation-container">
      			
   			  	<button onClick={(e)=> setShowForm(true)} className="final-reservation">Viimeistele varaus</button>
   			  </div> 
        	  
    </div>
	


  )
}
	else {
		return (
			<div className="yhdistys-container">
			<div className="yhdistys-wrapper">
			<button onClick={(e)=> setShowForm(false)}>Muuta valittuja aikoja</button>
			<ReservationForm reservation={reservation} id={id} name={name} type={type} showForm={showForm} setShowForm={setShowForm}/>
			</div>
			</div>
			)
	}
	

}

export default ReservationCalendar;