import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/news.css';


class News extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "news.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
     })
	}
		
	buildNews(content) {
		
		const image = (content) => {
			if(content.imagefilename !== "") {
				return (
					<img src={content.imagefilename} className="news-image" alt="kuva"/>
					)
			}
			else {

			}
		}

		return (
			<div className="news-wrapper" key={content.id}>
				<div className="news-item">
					<h3 className="news-header">{content.newsheader}</h3>
					<p className="news-date">{content.published_at}</p>
					{image(content)}
					<p className="news-body">{content.newsbody}</p>
				</div>
			</div>
		
		);
		}
		
	render() {
		
		return (
			<div className="news-container">
      			{this.state.content.reverse().map(content => this.buildNews(content))}
		  	</div>
		
		);
	}
}
		
export default News;