import {React, useState} from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/reservations.css';
import {formatDateToReadable} from './helperFunctions';

function ReservationForm(props) { 

	
	const { id, reservation, name, type} = props;
	
  	const [contactInfo, setContactInfo] = useState({
    	firstname: "",
    	lastname: "",
    	email: "",
    	phone: "",
    	address: "",
    	postoffice: "",
    	postal: "",

  	});
  	let formData = new FormData();
  	//console.log(reservation);
  	const sendReservation = async (e) => {
  		e.preventDefault();
  		const url = "create_reservation.php";
  		formData.append("product_id", id);
  		formData.append("type", type);

  		Object.keys(contactInfo).forEach(key => {
  			formData.append(key, contactInfo[key]);
  		} 
  		);

  		
  		//console.log(reservation);
  		
  		Object.keys(reservation).forEach(key => {
  			let array = [];
  			for(const pair of reservation[key].entries()) {
  				
  				if(pair[0] === "Date") {
  					array.push(pair[1]);
  				}
  				else {
  					array.push(pair[0]);
  				}
  				
  				
  			}
  			
  			var json_arr = JSON.stringify(array);
			formData.append(key, json_arr);
  		});
		
  		

		//console.log(formData);
		const { data } = await axiosConfig.post(url, formData, {});
		
		//console.log(data);
		window.location.replace("/reservation_done");
		  	

  	}



  	function buildReservationData(props) {
  		
  		return props.reservation.map(times => 
  			<div key={times.get("Date")} className="current-reservation-entry">
  				<p>{formatDateToReadable(new Date(times.get("Date")))}</p>
  				<p>Kellonajat:</p>
  				<p>{times.get("1")}</p>
  				<p>{times.get("2")}</p>
  				<p>{times.get("3")}</p>
  				<p>{times.get("4")}</p>
  				<p>{times.get("5")}</p>
  				<p>{times.get("6")}</p>
			</div>
  		)
  		
  	} 

  	const handleChange = (event) => {
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  	};
  	
	
	function buildReservationForm(props) {
		

		return (
			<div className="reservation-form-wrapper">
			<div><h1 className="reservation-form-headers">Varattava tuote: {name}</h1></div>
			<details className="reservation-details-wrapper">
			<summary  className="spoiler-text">Valitut ajat:(Klikkaa nähdäksesi)</summary>
			<div className="chosen-times">{buildReservationData(props)}</div>
			</details>

			<br />
			<br />
			<h2 className="reservation-form-headers">Varaajan tiedot</h2>
			<div className="reservation-form">
				
				<form onSubmit={sendReservation} onChange={handleChange}>
				
				<label>Etunimi</label><br />
				<input className="management-input" type="text" name="firstname" maxlength="20" defaultValue={contactInfo.firstname} required></input><br />
				<br />
				<label>Sukunimi</label><br />
				<input className="management-input" type="text" name="lastname" maxlength="20" defaultValue={contactInfo.lastname} required></input><br />
				<br />
				<label>Puhelin</label><br />
				<input className="management-input" type="text" name="phone" maxlength="10" defaultValue={contactInfo.phone} required></input><br />
				<br />
				<label>Email</label><br />
				<input className="management-input" type="email" name="email" maxlength="150" defaultValue={contactInfo.email} required></input><br />
				<br />
				<label>Katuosoite</label><br />
				<input className="management-input" type="text" name="address" maxlength="150" defaultValue={contactInfo.address}></input><br />
				<br />
				<label>Postitoimipaikka</label><br />
				<input className="management-input" type="text" name="postoffice" maxlength="100" defaultValue={contactInfo.postoffice}></input><br />
				<br />
				<label>Postinumero</label><br />
				<input className="management-input" type="text" name="postal" maxlength="10" defaultValue={contactInfo.postal}></input><br />
				<br />
				<br />
				<input type="submit" value="Tee varaus" className="submit-button"></input>

				</form>
			</div>
			</div>
		);
		}
		
	
		return (
		<>
      			{buildReservationForm(props)}
		</>	
		);
	
}
		
export default ReservationForm;