import '../css/footer.css';
import React from 'react';
import {Link} from 'react-router-dom';
import axiosConfig from '../backend/axios/axiosConfig';
import EUlogo from '../logos/Lippu.jpg'
import Leader from '../logos/Leader.jpg'
import LounaPlussa from '../logos/LounaPlussa.jpg'

class Footer extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "footer.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
     })
	}
		
	buildFooter(content) {
		
		return (
			
			<div key={content.id} className="footer">
				<p className="footer-item">Pohjois-Tammelan maa- ja kotitalousseura ry</p>
				<p className="footer-item">Y-Tunnus: {content.ytunnus}</p>
				<p className="footer-item">Puhelin: {content.puhelin}</p>
				<p className="footer-item">Tilinumero: {content.tilinumero}</p>
				
			</div>
			
			
		
		);
		}
		
	render() {
		
		return (
		<div className="footerDiv">
			<div className="footer">
      			{this.state.content.map(content => this.buildFooter(content))}
			</div>
			<div className="footer-logos">
				<Link to="/projectfunding">
					<img src={EUlogo} alt="logo" width="200px" height="50px"/>
					<img src={Leader} alt="logo" width="100px" height="50px" />
					<img src={LounaPlussa} alt="logo" width="200px" height="50px"/>
				</Link>
			</div>
		</div>		
		);
	}

}
	
	
export default Footer;