import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/yhdistys.css'
import {Link} from 'react-router-dom'

class Yhdistys extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "description.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
     })
	}
		
	buildDescription(content) {
		
		return (
			<div key={content.id} className="yhdistys">
				<Link to="/galleria">Kuvia tapahtumistamme</Link>
				<br />
				<p>{content.description}</p>
				<img src={content.image2} alt="kuva2"  className="content-image"/>
				<p>{content.description2}</p>
				<a href={content.historiikki} rel="noreferrer">Seuran historiikki</a>
			</div>
		
		);
		}
		
	render() {
		
		return (
		<div className="yhdistys-container">
			<div className="yhdistys-wrapper">
      			{this.state.content.map(content => this.buildDescription(content))}
			</div>
		</div>		
		);
	}
}
		
export default Yhdistys;