import React from 'react'
import {useState, useEffect} from 'react';
import Calendar from 'react-calendar';
import '../../css/reservations.css';
import {formatDate, formatDateToReadable} from '../helperFunctions';

const time = [{id: 1, time:'08:00-10:00'},
              {id: 2, time:'10:00-12:00'},
              {id: 3, time:'12:00-14:00'},
              {id: 4, time:'14:00-16:00'},
              {id: 5, time:'16:00-18:00'},
              {id: 6, time:'18:00-20:00'}
              ]




function Times(props) {

 const [event, setEvent] = useState('');
 const [info, setInfo] = useState(false);
 const [times, setTimes] = useState(props.times);
 const [date, setDate] = useState(props.date);
 const [chosen, setChosen] = useState(["white-all-free",
                                       "white-all-free", 
                                       "white-all-free", 
                                       "white-all-free", 
                                       "white-all-free", 
                                       "white-all-free"])


 let selectAll = false;
 const formData =  new FormData(); 
 
 formData.append("Date", formatDate(props.date));
  

  
  
  useEffect(() => {
    setTimes(props.times);
    props.setReservation(props.reservation);
  }, [props.times, props.reservation]);
  



  const deleteFromReservation = (e) => {
    var array = [...props.reservation]; 
    let value = e.target.value;

    for(let i = 0; i < array.length; i++) {
      if(array[i].get("Date") === value) {
        array.splice(i, 1);
        props.setReservation(array);
      }
    }
    
  }

  



  function TimeSlots({id, timeslot, times, reservation}) {
    //console.log(times);
    const [classes, setClasses] = useState("white-all-free");
    
    const handleTimeChange = (e) => {
      let copyClasses = [...classes];
      let array = [...props.reservation];

      if(!formData.get(id)) {
        copyClasses = "green-chosen-time";
        formData.append(id, document.getElementById(id).innerText);
        //console.log(formData);
      }
      else {
        formData.delete(id);
        copyClasses = "white-all-free";
        //console.log(formData);
      }

      setClasses(copyClasses);


      if(array.length === 0) {
        array.push(formData);
        props.setReservation(array);
       
      }
      else {
        let counter = 0;
        let value;
        for(let i = 0; i < array.length; i++) {
          
         
          if(array[i].get("Date") === formData.get("Date")) {
             counter++;
             
          }

          let formKeys = formData.keys();
          for (const key of formKeys) {
             
            if(typeof array[i] !== "undefined") {
            if(array[i].get(key) 
              && key !== "Date" && formData.get("Date") === array[i].get("Date")) {
              array[i].delete(key);
              let arr = Array.from(array[i].entries());
                if(arr.length === 1) {
                array.splice(i, 1);
                props.setReservation(array);
              }
            }
           

            else if(!array[i].get(key) && formData.get("Date") === array[i].get("Date")) {
              value = formData.get(key);
              array[i].append(key, value);
            }
            
          }
        }

      }
         props.setReservation(array);
        if(counter === 0) {
           array.push(formData);
          props.setReservation(array);
        }
        

       
      }
    }

    let className = classes;
    if(typeof times !== "undefined" && Object.keys(times).length > 0) {
      times.forEach(time => {
        if(parseInt(time.timeslot) === id) {
          className = "red-all-reserved";
        }
      } 
      )
      
    }
     
    else {
      className = classes;
    }

    if(typeof reservation !== "undefined" && Object.keys(reservation).length > 0) {
      for(let i = 0; i < reservation.length; i++) {
        //console.log(reservation[i]);
        if(reservation[i].get(id) && reservation[i].get("Date") === formatDate(props.date)) {

            className = "green-chosen-time";
          }
        
      }
    }


    return ( 
          <div key={id}>
            <button id={id} onClick={(e)=>handleTimeChange(e)} className={className}> {timeslot} </button>
          </div>
    )

  }


  function currentReservation () {
    let reservation = [...props.reservation];

    if(typeof reservation !== "undefined") {
      return reservation.map(times => 

        <div key={times.get("Date")} value={times.get("Date")} className="current-reservation-entry">
          <button onClick={(e) => deleteFromReservation(e)} value={times.get("Date")} class="remove-from-reservation">Poista varauksesta</button>
          <p>{formatDateToReadable(new Date(times.get("Date")))}: </p>
          <p>{times.get("1")}</p>
          <p>{times.get("2")}</p>
          <p>{times.get("3")}</p>
          <p>{times.get("4")}</p>
          <p>{times.get("5")}</p>
          <p>{times.get("6")}</p>
          <br />
      
      <br />
      </div>

      )
    } 
    
  }

   function DateLabel() {
    let date = formatDateToReadable(props.date);
    return(
      <div><h3>{date}</h3></div>
      )
    }

  

  const selectAllAvailableDates = () => {
    
    time.forEach(time => {
    if(!formData.get(time.id) && document.getElementById(time.id).className !== "red-all-reserved") {
      formData.append(time.id, time.time);
      document.getElementById(time.id).className = "green-chosen-time";
      //console.log(formData);
    }
    else if(document.getElementById(time.id).className === "red-all-reserved"){ 

    }

    else { 
      formData.delete(time.id);
      document.getElementById(time.id).className = "white-all-free";
      //console.log(formData);
    }
    });
    
  }

  


  function timeSlots() {
   


    let buttons = time.map(timeslots => 
        <TimeSlots id={timeslots.id} timeslot={timeslots.time} key={timeslots.id} times={times} reservation={props.reservation}/>
    );


    return (
      <div className="times calendar-container">
        <div className="choose-times">
        <DateLabel />
        {buttons}
        </div>

        <div className="chosen-times-container">
          <h3>Varatut päivät ja kellonajat:</h3><br />
          <div className="chosen-times">{currentReservation()}</div>
        </div>
        
      </div>
    );
  }
 

  return (
    timeSlots()
 
  )
}

export default Times;