import React, {useState, useEffect} from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import '../../../css/liftup.css';
import {Link, useLocation} from 'react-router-dom';

function ProductListing() { 

	const location = useLocation();
  	const { contact, type, pathname } = location.state;

  	const [content, setContent] = useState();
  	

	const getProducts = async () => {
		const formData = new FormData();
		formData.append("contact_id", contact);
		formData.append("type", type);
		console.log(formData);
		const url = "product_listing.php"
		const { data } = await axiosConfig.post(url, formData);
  
      	setContent(data);
     }


	
	useEffect(() => {
			
    		if(typeof content === "undefined") {
    			
    			getProducts();
    			
    		}
    	

    	
  	});

  	function buildContent(props) {
  		
  		if(typeof content !== "undefined" && content.length !== 0) {
  			return props.map(content => buildLiftups(content));
  		}
  		else {
  			return <div>Et ole yhteyshenkilönä missään tuotteessa.</div>
  		}
  		
  	}
		
	function buildLiftups(content) {
		return (
			<div className="machine-listing-liftup-wrapper" key={content.id}>
				<Link to={{ pathname: pathname + '/' + content.id  }} 
					state={{ id: content.id, image: content.imagefilename, 
								name: content.nimi, 
								osoite: content.osoite,
								etunimi: content.etunimi,
								sukunimi: content.sukunimi,
								hinta: content.hinta,
								hintaperuste: content.hintaperuste,
								hinta2: content.hinta2,
								hintaperuste2: content.hintaperuste2,
								linkki: content.linkki,
								contact_id: content.contact_id,
								type: content.type,
								muuta: content.muuta }}
								className="machine-listing-liftup">
					<img src={content.imagefilename} className="machine-listing-liftup-image" alt="kuva"/>
					<div className="machine-listing-liftup-info">
						<h3 className="machine-listing-liftup-text">{content.nimi}</h3>
					</div>
				</Link>
			</div>
		);
	}


		return (
			
			
			<div className="machine-listing-liftups-container">
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>
      			{buildContent(content)}
			</div>
			
		);
	}

		
export default ProductListing;