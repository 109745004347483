import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/contacts.css';

class Contacts extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
			content2: [],
		}
	};

	componentDidMount() {
		const url = "contacts.php";
		const url2 = "contacts_kotitalousnaiset.php";

		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
		});
		
		axiosConfig.get(url2).then(response => response.data).then((data) => {
      this.setState({ content2: data })
     });
	}


	
	/*vuokraajat listing of contacts was deprecated just before launch, but I will
	leave it here if it needs to be put online at some point*/	

	

	buildContacts(content, content2) {
		let vuokraajat = [];
		let toimihenkilot = [];

		vuokraajat = content.filter(contact => {
    		return contact.rooli === 'Yhteyshenkilö';
 		});
		
		toimihenkilot = content.filter(contact => {
    		return contact.rooli !== 'Yhteyshenkilö';
 		});
	

		const vuokraajaKontaktit = vuokraajat.map(vuokraaja => 
			<div className="contact" key={vuokraaja.id}>
				<h3>{vuokraaja.etunimi} {vuokraaja.sukunimi}</h3>
				<p>{vuokraaja.puhelin}</p>
				<p>{vuokraaja.email}</p>
			</div>
		);




		const toimihenkiloKontaktit = this.buildContactList(toimihenkilot);
		const kotitalousnaiset = this.buildContactList(content2);
					
  		return (
  			<div>
  				<div className="toimihenkilo-div">
  					<h2>Seuran johtokunta</h2>
  					<div className="contact-container">
					{toimihenkiloKontaktit}
					</div>
				</div>
				<div className="toimihenkilo-div">
  					<h2>Naisosaston johtokunta</h2>
  					<div className="contact-container">
					{kotitalousnaiset}
					</div>
				</div>

				
			</div>
		
		);
	}

	buildContactList(c) {
		return(
			c.map(henkilo => 
			<div className="contact" key={henkilo.id}>
				<h3 className="contact-name">{henkilo.etunimi} {henkilo.sukunimi}</h3>
				<p className="contact-role">{henkilo.rooli}</p>
				<p className="contact-phone">{henkilo.puhelin}</p>
				<p className="contact-email">{henkilo.email}</p>
			</div>
		)
		

		);
	}
		
	render() {
		const content = this.state.content;
		const content2 = this.state.content2;

		return (
		<div className="contacts-wrapper">
      		{this.buildContacts(content, content2)}
		</div>
		
		);
	}
}
		
export default Contacts;