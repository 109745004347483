import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/liftup.css';
import {Link} from 'react-router-dom';

class Liftups extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "liftups.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
     })
	}
		
	buildLiftups(contentUrl) {
		
		return (
			<div className="liftup-wrapper" key={contentUrl.id}>
			<Link to={contentUrl.path} className="liftup">
				<img src={contentUrl.imagefilename} className="liftup-image" alt="kuva"/>
				<h3 className="liftup-text">{contentUrl.liftuptext}</h3>
			</Link>
			</div>
		
		);
		}
		
	render() {
		
		return (
			<div className="liftups-container">
      	{this.state.content.map(contentUrl => this.buildLiftups(contentUrl))}
		  </div>
		
		);
	}
}
		
export default Liftups;