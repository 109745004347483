import {useState} from 'react';
import Calendar from 'react-calendar';
import '../../css/reservations.css';
import Times from './times'

import React from 'react'

function Time(props) {
 
 return (
 <div>
  {props.showTime ? <Times date={props.date} times={props.times} setReservation={props.setReservation} reservation={props.reservation}/> : null}
 </div>
  )
}

export default Time;
