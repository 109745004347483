import React from 'react';
import '../../css/maincontent.css';
import '../../css/links.css';
import '../../css/reservations.css';
import {Link} from 'react-router-dom';


class ContentManagement extends React.Component {

  addReservation () {
    let path = "";
    let type = ""; 
    let user_contact_id = ""; 
    let pathname = "";

    if(localStorage.getItem('user') === "is_super") {
      if(localStorage.getItem('user_type') === '0') {
        path = "/machines";
      }
      if(localStorage.getItem('user_type') === '1') {
        path = "/vuokrattavat_tarvikkeet";
      }
      if(localStorage.getItem('user_type') === '2') {
        path = "/muut_tuotteet";
      }
    }
    else {
      path = "/product_listing";
      type = localStorage.getItem("user_type");
      user_contact_id = localStorage.getItem("user_contact_id");
      if(localStorage.getItem('user_type') === '0') {
        pathname = "/machines";
      }
      if(localStorage.getItem('user_type') === '1') {
        pathname = "/tarvikkeet";
      }
      if(localStorage.getItem('user_type') === '2') {
        pathname = "/muut_tuotteet";
      }

    }

    return (
      
      <Link className="content-management-link" to={path} state={{contact: user_contact_id, type: type, pathname: pathname }}>Luo varaus</Link>
      

    );

  }

  manageUsers () {
    if(localStorage.getItem('user') === "is_admin" || localStorage.getItem('user') === "is_super") {
      return (
        <div className="manage-users-container">
         <h2 className="reservation-management-header">Käyttäjien hallinta</h2>
        <div className="content-management-linkbar reservation-fonts">
          <Link to="/add_user" className="content-management-link">Lisää käyttäjä</Link>
          <Link to="/delete_users" className="content-management-link">Poista käyttäjä</Link>
          <Link to="/contact_listing" className="content-management-link">Yhteystietojen hallinta</Link>
          <Link to="/update_password" className="content-management-link">Vaihda oma salasana</Link>
        </div>
        </div>
      );
    }
    else {
      return (
        <div className="manage-users-container">
         <h2 className="reservation-management-header">Käyttäjätunnuksen hallinta</h2>
        <div className="content-management-linkbar reservation-fonts">
          <Link to="/update_password" className="content-management-link">Vaihda oma salasana</Link>
        </div>
        </div>
      );

    }
  }

  manageLiftup () {
    if(localStorage.getItem('user') === "is_admin") {
      return (
        <div>
         <h2>Etusivun nostojen hallinta</h2>
        <div className="content-management-linkbar">
          <Link to="" className="content-management-link">Lisää nosto</Link>
          <Link to="" className="content-management-link">Poista nosto</Link> 
        </div>
        </div>
      );
    }
  }

  manageReservations () {
    if(localStorage.getItem('user') === "is_admin" || localStorage.getItem('user') === "is_super") {
      return (
    <div>
    
        <div className="content-management-linkbar reservation-fonts">
          <Link to="/check_nonvalidated_reservations" className="content-management-link">Vahvistamattomat varaukset</Link>
          <Link  to="/show_reservations" className="content-management-link">Kaikki varaukset</Link>
          <Link to="/list_invoices" className="content-management-link">Laskut</Link>
          <Link to="/add_product" className="content-management-link">Lisää kohde</Link> 
          <Link to="/delete_product" className="content-management-link">Kohteet</Link>
          <Link to="/update_yhdistys_data" className="content-management-link">Päivitä yhdistyksen tietoja</Link>
          {this.addReservation()}
        </div>
      </div>
      );
    }
    if(localStorage.getItem("user") === "is_updater") {
      return (
        <div className="content-management-linkbar reservation-fonts">
          <Link to="/check_nonvalidated_reservations" className="content-management-link">Vahvistamattomat varaukset</Link>
          <Link  to="/show_reservations" className="content-management-link">Kaikki varaukset</Link>
          <Link to="/delete_product" className="content-management-link">Kohteet</Link>
          <Link to="/list_invoices" className="content-management-link">Laskut</Link>
          {this.addReservation()}
        </div>
    );}
  }

  manageContent () {
    if(localStorage.getItem('user') === "is_admin" || (localStorage.getItem('user') === 'is_super' && (localStorage.getItem("user_type") === "0" || localStorage.getItem("user_type") === "1"))) {
      return (
        <div className="management-grid-top">
        <h2>Sivujen sisällönhallinta</h2>
        <div className="content-management-linkbar">
        
        {/*
        <div className="dropdown">
          <Link to="" className="content-management-link">Toimintasuunnitelma</Link>
          <div className="content-management-dropdown">
            <Link to="" className="content-management-link">Lisää toimintasuunnitelma</Link>
            <Link to="" className="content-management-link">Poista toimintasuunnitelma</Link>
          </div>
        </div>

         <div className="dropdown">
          <Link to="" className="content-management-link">Galleria</Link>
          <div className="content-management-dropdown">
            <Link to="" className="content-management-link">Lisää kuvia</Link>
            <Link to="" className="content-management-link">Poista kuvia</Link>
          </div>
        </div>
        */}
         <div className="dropdown">
          <Link to="/list_news" className="content-management-link">Ajankohtaista</Link>
          
        </div>
        {/*
         <div className="dropdown">
          <Link to="" className="content-management-link">Yhteystiedot</Link>
          <div className="content-management-dropdown">
            <Link to="" className="content-management-link">Lisää yhteystieto</Link>
            <Link to="" className="content-management-link">Poista yhteystieto</Link>
          </div>
        </div>
        */}
        </div>
        
        
        
      </div>
        );
    }
  }


  
  render() {
    
  return (
    <div className="management-grid">
     
      {this.manageContent()}

      <div className="management-grid-middle">
      <h2 className="reservation-management-header">Varausjärjestelmän hallinta</h2> 
      {this.manageReservations()}
       
      </div>
      <div className="management-grid-bottom">
        {this.manageUsers()}
      </div>
    </div>
    );
  }
}
  
export default ContentManagement;
