import React from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import '../../../css/reservations.css';
import {Link} from 'react-router-dom';


const formdata = new FormData();
const formdata2 = new FormData();

class ShowReservations extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
			response: '',
		}


	};

	 

	componentDidMount() {
		const url = "show_reservations.php"
		formdata2.append("creds", localStorage.getItem("user"));
		formdata2.append("contact_id", localStorage.getItem("user_contact_id"));
		formdata2.append("type", localStorage.getItem("user_type"));
		axiosConfig.post(url, formdata2).then(response => response.data).then((data) => {
      this.setState({ content: data })
      //console.log(this.state.content);
     })
	}


	handleInputChange = (event) => {

		const target = event.target;
		const name = target.name;
		const id = target.id;

		if(formdata.get(id)) {
			formdata.delete(id);
		}
		else {
			formdata.append(id, name);
			
		}
		//console.log(formdata);

	}

	handleSubmit = async (event) => {
		if(window.confirm("Olet poistamassa varauksen järjestelmästä. Toimintoa ei voi peruuttaa. Haluatko jatkaa?")) {
		event.preventDefault();
		const url = "delete_reservations.php";


		const { data } = await axiosConfig.post(url, formdata, {
    	})
    	this.setState({response: data});
    	window.location.reload();
    	}

	}

	inputBox(reservation) {
			if(reservation[1] === "0") {
				return(
					<input 
				type="checkbox" 
				name={reservation[2].res_ref}
				id={reservation[2].res_ref}
				onChange={this.handleInputChange}/>
					)
			}
			else {
				
			}
		}


		
	buildReservationInfo(reservation) {
		
		return (
			<>
			<tr key={reservation[2].res_ref}>
			<td><Link to={{ pathname: '/show_reservations/' + reservation[2].res_ref  }} state={{ res_ref: reservation[2].res_ref, product: reservation[0].nimi }}>{reservation[2].res_ref}</Link></td>
			<td className="management-table-cell">{reservation[0].nimi}</td>
			<td className="hide-cell-mobile management-table-cell">{reservation[2].first_name}</td>
			<td className="hide-cell-mobile management-table-cell">{reservation[2].last_name}</td>
			<td className="hide-cell-mobile management-table-cell">{reservation[2].phone}</td>
			<td className="hide-cell-mobile management-table-cell">{reservation[2].email}</td>
			<td>{this.inputBox(reservation)}</td>
			</tr>
			</>
			)
	
	}

	buildReservationList(content) {
		if(typeof content !== "undefined" && content.length > 0) {
			return(
				content.map(reservation => this.buildReservationInfo(reservation))
				)
		}
		else {
			return (
				<p>Tällä hetkellä ei ole varauksia</p>
				)
		}	
	}

	render() {
		let content = this.state.content;
		if(content.length !== 0) {
			
			return (
			<div className="reservation-listing-container reservation-fonts">
			<Link to="/contentmanagement"  className="return-link">Takaisin hallintapaneeliin</Link>
			<h1>Kaikki varaukset:</h1>
			<form onSubmit={this.handleSubmit}>
			<table className="management-table">
			<tbody>
			<tr>
				<th>Varausnumero:</th>
				<th>Tuote:</th>
				<th className="hide-cell-mobile management-table-cell">Asiakkaan etunimi:</th>
				<th className="hide-cell-mobile management-table-cell">Asiakkaan sukunimi:</th>
				<th className="hide-cell-mobile management-table-cell">Puhelin:</th>
				<th className="hide-cell-mobile management-table-cell">Email:</th>
				<th>Poista:</th>
			</tr>
      			{this.buildReservationList(content)}
      		</tbody>
      		</table>
      		<br />
      		<input type="submit" value="Poista valitut" className="return-link"/>
      		</form>
			</div>
		);
		}
		else {
			return (
				<div className="reservation-listing-container">
				<Link to="/contentmanagement">Takaisin hallintapaneeliin</Link>
				<p>Tällä hetkellä ei ole varauksia</p>
				</div>
				)
		}

		
	}
}
		
export default ShowReservations;