import {React, useState, useEffect, useRef} from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import {Link, useLocation} from 'react-router-dom';
//import '../../../css/liftup.css';
import '../../../css/reservations.css';


function UpdateProductForm() { 
	const location = useLocation();
	const { name, type, id, contact_id, hinta, hintaperuste, hinta2, hintaperuste2, link, muuta, menu_weight, imagefilename, osoite } = location.state;
	
  	const [contactInfo, setContactInfo] = useState([]);

  	const [selectedFile, setSelectedFile] = useState(null);
  	const [imagePreview, setImagePreview] = useState(imagefilename);
  	
  	const [response, setResponse] = useState(""); 

  	const [address, setAddress] = useState();
  	
  	const [userCreds, setUserCreds] = useState();

  	const [newContactID, setNewContactID] = useState(null);

  	const [contactList, setContactList] = useState([]);

  	const [selectedOption, setSelectedOption] = useState('option2')
  	

  	const [productInfo, setproductInfo] = useState({
  		name: name,
  		hinta: hinta,
  		hintaperuste: hintaperuste,
  		hinta2: hinta2,
  		hintaperuste2: hintaperuste2,
  		link: link,
  		muuta: muuta,
  		menu_weight: menu_weight,
  		contactId: contact_id,
  		osoite: osoite
  	})


  	

  	const getContacts = async (type) => {
  		
  		let formData2 = new FormData();
  		formData2.append("type", type);
  		formData2.append("creds", localStorage.getItem("user"));

  		const url = "create_user_contacts.php"
		const { data } = await axiosConfig.post(url, formData2);
  		setContactList(data);

  		
  		

  	};

  	const getProductContact = async (type) => {
  		let formData3 = new FormData();
  		formData3.append("type", type);
  		formData3.append("id", contact_id);
  		//console.log(formData3);
  		const url2 = "product_contact.php";
		const { data } = await axiosConfig.post(url2, formData3);
		let contact = data[0];
		setContactInfo(contact);
		
  	}



  	useEffect(() => {
		
    	if(Object.keys(contactList).length === 0) {
    		getContacts(type);
    		getProductContact(type);
    		//console.log("\u0080");
    	}
    	
    	
    	
  	});

  	const sendUpdateProduct = async (e) => {
  		if(window.confirm("Olet päivittämässä tuotteen tietoja. Haluatko jatkaa?")) {
  		e.preventDefault();
  		

  		const url = "update_product_data.php";
  		let formData = new FormData();
  		
  		formData.append('id', id);
		formData.append('type', type);
		if(typeof selectedFile !== null) {
  			formData.append('image', selectedFile);

  		}

		Object.keys(productInfo).forEach(key => {
			formData.append(key, productInfo[key]);
		});
		
		/*
		if(typeof address !== "undefined") {
			formData.append("address", address);
		}
		*/
  		

		//console.log(formData);
		const { data } = await axiosConfig.post(url, formData, { headers: {
      	'Content-Type': 'multipart/form-data'
    	}});
		//console.log(data);
		}
		window.location.replace("/delete_product");
  	}


  	


  	

  	
  	

  	

  	const handleChange = (event) => {
    	setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  	};

  	const handleProductChange = (event) => {
    	setproductInfo({ ...productInfo, [event.target.name]: event.target.value });
  	};

  	const handleImageChange = (event) => {
  		setSelectedFile(event.target.files[0]);
  		setImagePreview(URL.createObjectURL(event.target.files[0]));
  		//console.log(event.target.files[0]);
  	}
  	
  	const createContactList = () => {
  		if(typeof contactList !== "undefined" && contactList.length > 0) {
  			return (
  				contactList.map(contact => 
  			<option value={contact.id} key={contact.id} id={contact.id}>{contact.etunimi} {contact.sukunimi}, {contact.puhelin}, {contact.email}</option>
  			)
  			);
  		}
  	}

  	const addressField = () => {
  		if(type === "2") {
  			return (
  				<div>
  				<label>Osoite:</label><br />
  				<input type="text" name="osoite" value={productInfo.osoite} onChange={handleProductChange}></input>
  				</div>
  			)

  		}
  	}

  	
	
	function buildReservationForm(props) {


		let contactContent;
  		if(selectedOption ==="option1") {
  			contactContent = (
  				<div><select onChange={handleProductChange} value={newContactID} name="contactId" required>
  				<option value="">Valitse:</option>
  				{createContactList()}</select></div>
  			);
  		}
  		else {
  			if(typeof contactInfo !== "undefined") {
  			contactContent = (
  				<div>
  					<p>{contactInfo.etunimi} {contactInfo.sukunimi}</p>
  					<p>{contactInfo.puhelin}</p>
  					<p>{contactInfo.email}</p>
  				</div>
  				
  			);
  			}
  		}


		return (
			<div className="reservation-fonts">
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>

			<h1>Päivitä tuotteen tiedot:</h1>
			<div className="reservation-form">
				<form onSubmit={sendUpdateProduct}>
				<br />
				{response}
				<br />
				<br />
				
				<label>Tuotenimi:</label><br />
				<input className="management-input" type="text" name="name"  value={productInfo.name} onChange={handleProductChange}></input>
				<br />
				<label>Hinta:</label><br />
				<input className="management-input" type="text" name="hinta" value={productInfo.hinta} onChange={handleProductChange}></input>
				<br />
				<label>Hintaperuste:</label><br />
				<input className="management-input" type="text" name="hintaperuste" value={productInfo.hintaperuste} onChange={handleProductChange}></input>
				<br />
				<label>Hinta2:</label><br />
				<input className="management-input" type="text" name="hinta2" value={productInfo.hinta2} onChange={handleProductChange}></input>
				<br />
				<label>Hintaperuste2:</label><br />
				<input className="management-input" type="text" name="hintaperuste2" value={productInfo.hintaperuste2} onChange={handleProductChange}></input>
				<br />
				<br />
				<label>Nykyinen kuvatiedosto:</label><br />
				<img src={imagePreview} className="machine-liftup-image"/><br />
				<br />
				<label>Vaihda kuvatiedosto:</label><br />
				<input className="management-input" type="file" onChange={handleImageChange}/>
				<br />
				<br />
				<label>Listausjärjestys(mitä korkeampi arvo, sitä ylempänä kohdelistauksessa kohde on):</label><br />
				<input className="management-input" type="text" name="menu_weight" value={productInfo.menu_weight} onChange={handleProductChange}></input>
				<br />
				<label>Linkki:</label><br />
				<input className="management-input" type="text" name="link" value={productInfo.link} onChange={handleProductChange}></input>
				<br />
				{addressField()}


				<label>Muut tiedot:</label><br />
				<textarea className="management-input" type="text" name="muuta" value={productInfo.muuta} onChange={handleProductChange}></textarea>


				<br />
				
				<br />
				<br />
				<br />
				<div className="radio">
      			<label>
        			<input type="radio" 
        				   value="option2"
        				   id="option2" 
        				   checked={selectedOption === 'option2'}
        				   onChange={(e) => setSelectedOption(e.target.value)}/>
        			Nykyiset yhteystiedot:
      			</label>
    			</div>
    			<br />
				<div className="radio">
      			<label>
        			<input type="radio" 
        				   value="option1"
        				   id="option1"
        				   checked={selectedOption === 'option1'} 
        				   onChange={(e) => setSelectedOption(e.target.value)}/>
       				Valitse toiset yhteystiedot:

      			</label>
    		</div>
    		<br />
    		<br />
    		
    		<br />
    		Yhteystiedot:
    		{contactContent}
    		<br />
    		<br />
				<input type="submit" value="Päivitä tuote" className="return-link"></input>

				</form>
			</div>
			</div>
		);
		}
		
	
		return (
		<div className="yhdistys-container">
			<div className="yhdistys-wrapper">
      			{buildReservationForm()}
			</div>
		</div>		
		);
	
}
		
export default UpdateProductForm;