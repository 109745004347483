import React, { useEffect, useState } from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/liftup.css';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {buildContact} from './helperFunctions';
import '../css/reservations.css';

const Tarvike = () => {
  
  const location = useLocation();
  const { id, image, name, etunimi, sukunimi, hinta, hintaperuste, hinta2, hintaperuste2, contact_id, type, linkki, muuta } = location.state;
  const [contact, setContact] = useState([]);
 
  
  	const getContact = async () => {
  		
  		const url = "product_contact.php"
  		const formData =  new FormData();
  		formData.append('id', contact_id);
  		formData.append('type', type);
			const { data } = await axiosConfig.post(url, formData, {});
  		setContact(data);
  	};


	useEffect(() => {
	
    	if(Object.keys(contact).length === 0) {
    		getContact();
    	}
    	
    	
    	
  	});

	const buildLiftups = () => {
		
		return (
			<div className="liftup-wrapper" key={id}>
				<div className="machine-liftup liftup-text">
					<img src={image} className="machine-liftup-image" alt="kuva"/>
					<div className="machine-info">
					<h3>{name}</h3>
					<p>Hinta: {hinta} /{hintaperuste}</p>
					<p>{hinta2} {hintaperuste2}</p>
					<p>Yhteyshenkilö: {etunimi} {sukunimi}</p>
					<a href={linkki} target="_blank" rel="noreferrer">Käyttöohje</a>
					<div>{contact.map(contact => buildContact(contact))}</div>
					<p>Muut tiedot: {muuta} </p>
					<Link className="return-link" to={{ pathname: '/reservation_calendar/' + id  }} state={{ name: name, type: type }}>Tee varaus</Link>
					
					</div>
				</div>
			</div>
		
		);
	};


  return (
    

    <div className="machine-liftups-container">
    <Link to="/vuokrattavat_tarvikkeet" className="machine-liftup-return">Takaisin tarvikkeisiin</Link>
      	{buildLiftups()}
	</div>
   
  );
};

export default Tarvike;