import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/liftup.css';
import {Link} from 'react-router-dom';

class Tarvikkeet extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "tarvikkeet.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
     })
	}
		
	buildLiftups(content) {
		return (
			<div className="machine-listing-liftup-wrapper" key={content.id}>
				<Link to={{ pathname: '/vuokrattavat_tarvikkeet/' + content.id  }} 
					state={{ id: content.id, image: content.imagefilename, 
								name: content.nimi, 
								etunimi: content.etunimi,
								sukunimi: content.sukunimi,
								hinta: content.hinta,
								hintaperuste: content.hintaperuste,
								hinta2: content.hinta2,
								hintaperuste2: content.hintaperuste2,
								linkki: content.linkki,
								contact_id: content.contact_id,
								type: content.type,
								muuta: content.muuta }}
								className="machine-listing-liftup">
					<img src={content.imagefilename} className="machine-listing-liftup-image" alt="kuva"/>
					<div className="machine-listing-liftup-info">
						<h3 className="machine-listing-liftup-text">{content.nimi}</h3>
					</div>
				</Link>
			</div>
		);
	}

	render() {
		return (
			<div className="machine-listing-liftups-container">
      			{this.state.content.map(content => this.buildLiftups(content))}
			</div>
		);
	}
}
		
export default Tarvikkeet;