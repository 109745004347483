import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/liftup.css';
import {Link} from 'react-router-dom';

class Services extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "services.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
      //console.log(this.state.content)
     })
	}
		
	buildLiftups(content) {
		
		return (
			<div className="service-listing-liftup-wrapper" key={content.id}>
			<Link to={{ pathname: '/services/' + content.id  }} 
			state={{ id: content.id, 
					name: content.nimi, 
					etunimi: content.palvelu_etunimi,
					sukunimi: content.palvelu_sukunimi,
					linkki: content.linkki,
					contact_id: content.contact_id,
					muuta: content.muuta }}
			className="service-listing-liftup">
				
				<div className="service-listing-liftup-info">
					<h3 className="service-listing-liftup-text">{content.nimi}</h3>
					<p className="service-listing-liftup-smalltext">{content.palvelu_etunimi} {content.palvelu_sukunimi}</p>
				</div>
			</Link>
			</div>
		
		);
	}

	
		
	render() {

		
		return (
			<div className="service-listing-liftups-container">
      	{this.state.content.map(content => this.buildLiftups(content))}
		  </div>
		
		);
	}
}
		
export default Services;