import React from 'react';
import '../../../css/reservations.css';

const ReservationDone = () => {

	

  return (
    

    <div className="calendar-container">
    	Olet tehnyt varauksen!<br /> Saat varauksen tiedot sähköpostiisi hetken kuluttua.
    	Kun varaus on vahvistettu kohteen yhteyshenkilön toimesta saat laskun sähköpostiisi.  
    
		</div>
   
  );
};

export default ReservationDone;