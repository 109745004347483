import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/toimintasuunnitelma.css';
import '../css/yhdistys.css';
import {Link} from 'react-router-dom';

class ToimintaKertomukset extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "toimintakertomukset.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      		this.setState({ content: data })
      		//console.log(this.state.content)
     	})
	}
		
	buildToimintaKertomukset(content) {
		
		return (
			<div key={content.id} className="toimintakertomus">
				<a rel="noreferrer" href={content.toimintakertomus}>{content.vuosi}</a>
			</div>
		
		);
		}
		
	render() {
		
		return (
		<div className="toimintasuunnitelma-container">
			<div className="toimintasuunnitelma-wrapper">
			<h2>Toimintakertomukset</h2>
      			{this.state.content.reverse().map(content => this.buildToimintaKertomukset(content))}
			</div>
		</div>		
		);
	}
}
		
export default ToimintaKertomukset;