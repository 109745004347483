import '../css/gallery.css';
import React from "react";
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/loader.css';

/**
 * Given a DOM element, searches it for <img> tags and checks if all of them
 * have finished loading or not.
 * @param  {Element} parentNode
 * @return {Boolean}
 */
 
 
function imagesLoaded(parentNode) {
    const imgElements = [...parentNode.querySelectorAll("img")];
      for (let i = 0; i < imgElements.length; i += 1) {
        const img = imgElements[i];
        if (!img.complete) {
          return false;
        }
      }
  return true;
}

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      gallery:[]
    };
  }
  
  componentDidMount() {
    const url = "gallery.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ gallery:data })
      //console.log(this.state.gallery)
    })
	}
	
  handleImageChange = () => {
    this.setState({
      loading: !imagesLoaded(this.galleryElement)
    });
  };

  renderSpinner() {
    if (!this.state.loading) {
      return null;
    }
    return <span className="loader" />;
  }

  renderImage(imageUrl) {
    return (
      <a href={imageUrl.imagefilename} target="_blank" key={imageUrl.id} className="thumbnail-link">
        <div className="thumbnail-wrapper">
          <img
            src={imageUrl.imagefilename}
            alt="kuva"
		        className="thumb"
		        onLoad={this.handleImageChange}
            onError={this.handleImageChange}/>
            <p className="thumbnail-description">{imageUrl.description}</p>
        </div>
      </a>
    );
  }

  render() {
    return (
    <div className="gallery" ref={element => {this.galleryElement = element;}}>
      {this.renderSpinner()}
      <div className="images">
        {this.state.gallery.map(imageUrl => this.renderImage(imageUrl))}
      </div>
    </div>
    );
  }
}

export default Gallery;

