import {Link} from 'react-router-dom';
import '../css/links.css';

function logout () {
		localStorage.clear();
		window.location.replace("/");
		
}

function Login() {
	if(localStorage.getItem('user')) {
		let username = localStorage.getItem('username');
		return (
			<div className="login-div">
			
				<Link to="" className="login" onClick={logout}>{username}</Link>
				
			</div>
		)
	}

	return (
		<div className="login-div">
				<Link to="/loginpage" className="login">Intra</Link>
		</div>
	)
}

export default Login;