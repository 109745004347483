import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/joinmember.css'
import {buildContact} from './helperFunctions';

class JoinMember extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			contact: [],
			content: [],
		}
	};

	componentDidMount() {
		const url = "machine_contact.php"
		const formData = new FormData();
		const id = "10"; /*jäsenvastaavan contact-taulun id*/
		formData.append('id', id);
		axiosConfig.post(url, formData).then(response => response.data).then((data) => {
      this.setState({ contact: data })
     })

		const content_url = "joinmember.php"
		axiosConfig.get(content_url).then(response => response.data).then((data) => {
      this.setState({ content: data })
     })

	}
	


	buildJoinMember(content) {
		
		return (
			<div key={content.id} className="joinmember">
				<h3>Liity jäseneksi</h3>
				<p>{content.description}</p>
				<div>{this.state.contact.map(contact => buildContact(contact))}</div>
				
			</div>
		
		);
	}
		
	render() {
		
		return (
		<div className="joinmember-container">
			<div className="joinmember-wrapper">
      			{this.state.content.map(content => this.buildJoinMember(content))}
			</div>
		</div>		
		);
	}
}
		
export default JoinMember;