import {React, useState, useEffect, useRef} from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import {Link, useLocation} from 'react-router-dom';
import '../../../css/liftup.css';


function UpdateContactForm() { 
	const location = useLocation();
	const { id,
			etunimi, 
			sukunimi, 
			puhelin, 
			email, 
			rooli, 
			address, 
			city, 
			postal_code,
			type } = location.state;

  	const [contactInfo, setContactInfo] = useState({
  		id: id,
    	etunimi: etunimi,
    	sukunimi: sukunimi,
    	email: email,
    	puhelin: puhelin,
    	rooli: rooli,
    	address: address,
    	city: city,
    	postal_code: postal_code

  	});
  	
  	
  	
  	const [response, setResponse] = useState(""); 

  	
  	
  	


  	
  	
  	
  	const sendUpdateContact = async (e) => {
  		if(window.confirm("Olet päivittämässä yhteystietoja. Haluatko jatkaa?")) {
  		e.preventDefault();
  		

  		const url = "update_contact_data.php";
  		let formData = new FormData();
  		
  	
		formData.append('type', type);
		

		Object.keys(contactInfo).forEach(key => {
			formData.append(key, contactInfo[key]);
		});
		

		
  		

		//console.log(formData);
		const { data } = await axiosConfig.post(url, formData);
		//console.log(data);
		}
		window.location.replace("/contact_listing");
  	}


  	

	
  	
  
  	const handleProductChange = (event) => {
    	setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  	};
  	
	
	function buildReservationForm(props) {
		

		return (
			<div className="reservation-fonts">
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>

			<h1>Päivitä yhteystiedot:</h1>
			<div className="reservation-form">
				<form onSubmit={sendUpdateContact}>
				<br />
				{response}
				<br />
				<br />
				
				<label>Etunimi:</label><br />
				<input className="management-input" type="text" name="etunimi"  value={contactInfo.etunimi} onChange={handleProductChange}></input>
				<br />
				<label>Sukunimi:</label><br />
				<input className="management-input" type="text" name="sukunimi" value={contactInfo.sukunimi} onChange={handleProductChange}></input>
				<br />
				<label>Puhelin:</label><br />
				<input className="management-input" type="text" name="puhelin" value={contactInfo.puhelin} onChange={handleProductChange}></input>
				<br />
				<label>Email:</label><br />
				<input className="management-input" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" type="text" name="email" value={contactInfo.email} onChange={handleProductChange}></input>
				<br />
				<label>Rooli:</label><br />
				<input className="management-input" type="text" name="rooli" value={contactInfo.rooli} onChange={handleProductChange}></input>
				<br />
				<label>Osoite:</label><br />
				<input className="management-input" type="text" name="address" value={contactInfo.address} onChange={handleProductChange}></input>
				<br />
				<label>Postitoimipaikka:</label><br />
				<input className="management-input" type="text" name="city" value={contactInfo.city} onChange={handleProductChange}></input>
				<br />
				<label>Postinumero:</label><br />
				<input className="management-input" type="text" name="postal_code" value={contactInfo.postal_code} onChange={handleProductChange}></input>
    		
    		<br />
    		<br />
    		
    		
				<input type="submit" className="return-link" value="Päivitä yhteystiedot"></input>

				</form>
			</div>
			</div>
		);
		}
		
	
		return (
		<div className="yhdistys-container">
			<div className="yhdistys-wrapper">
      			{buildReservationForm()}
			</div>
		</div>		
		);
	
}
		
export default UpdateContactForm;