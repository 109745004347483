import React from 'react';
import '../css/header.css';
import {Link} from 'react-router-dom';



function Header() {
	return (
		<div className="header-div">
			<Link to="/" className="header-text">
				<p >Pohjois-Tammelan maa- ja kotitalousseura ry</p>
			</Link>
		</div>
	)
}
export default Header;