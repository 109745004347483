import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/liftup.css';
import {Link} from 'react-router-dom';


class NewsLiftups extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "newsliftups.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
     })
	}
		
	buildNewsLiftups(content) {
		
		const image = (content) => {
			if(content.imagefilename !== "") {
				return (
					<img src={content.imagefilename} className="news-liftup-image" alt="kuva"/>
					)
			}
			else {

			}
		}
		
		return (
			<div className="liftup-wrapper" key={content.id}>
				<Link to="/news" className="newsliftup newsliftup-text">
					{image(content)}
					<div className="news-info">
						<h3>{content.newsheader}</h3>
						<p className="news-liftup-date">Julkaistu : {content.published_at}</p>
						<p>{content.liftuptext}</p>
					</div>
				</Link>
			</div>
		
		);
		
		
		}
		
	render() {
		
		return (
		<div className="newsliftups-container">
      		{this.state.content.reverse().slice(0, 6).map(content => this.buildNewsLiftups(content))}
		</div>
		
		);
	}
}
		
export default NewsLiftups;