import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/calendar.css';

class EventCalendar extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
		}
	};

	componentDidMount() {
		const url = "calendar.php"
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
      //console.log(this.state.content)
     })
	}
		
	buildCalendar(content) {
		//console.log(content.src);
		
		
		return (
			<div className="calendar-wrapper"  key={content.id}>
				<iframe title="EventCalendar" src={content.src} frameBorder="0" scrolling="no" className="calendar-frame"></iframe>
			</div>
		
		);
		}
		
	render() {
		
		return (
		<div className="calendar-container">
      		{ this.state.content.map(content => this.buildCalendar(content))}
		</div>
		
		);
	}
}
		
export default EventCalendar;


