import '../css/cookie.css';



function setConsentCookie () {
	document.cookie = "cookie_consent=1; SameSite=None; Secure; max-age=31536000";
	document.getElementById('cookie-block').style.display = "none";
}

function CookiesReminder () {

	if(document.cookie.split(';').some((item) => item.trim().startsWith('cookie_consent='))) {

	}
	else {
	
	return (
		<div className="cookie-div" id="cookie-block"> 
			<p className="cookie-reminder">Tämä sivusto käyttää ainoastaan toiminnan kannalta välttämättömiä evästeitä. Emme kerää tietoja käyttäjistämme.</p>
			<button onClick={setConsentCookie}>Hyväksy</button>
		</div>
	);
	}
}
	
	
export default CookiesReminder;