import React from 'react';
import axiosConfig from '../../backend/axios/axiosConfig';
import '../../css/reservations.css';
import {Link} from 'react-router-dom';


const formdata = new FormData();


class ListNews extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
			response: '',
		}


	};

	 

	componentDidMount() {
		
		let url = "news.php";
		
		
		axiosConfig.get(url).then(response => response.data).then((data) => {
      this.setState({ content: data })
      console.log(data);
      console.log(this.state.content);
     })
	}

	

	handleNotInUse = async (event, value) => {
		const formdata2 = new FormData();
		
			if(window.confirm(value === "0" ? "Olet piilottamassa uutisen etusivulta. Haluatko jatkaa?":"Olet nostamassa uutisen takaisin etusivulle. Toiminnon voi perua myöhemmin. Haluatko jatkaa?")) {
				event.preventDefault();
				const url = "change_news_not_in_use.php";


				formdata2.append("id", event.target.id);
				formdata2.append("show_this", value);
				
				const { data } = await axiosConfig.post(url, formdata2, {
    			})
    			this.setState({content: data});
    		
			}
		

	}

	handleInputChange = (event) => {

		
		if(formdata.get(event.target.name)) {
			formdata.delete(event.target.name);
		}
		else {
			let input = [JSON.stringify({type: event.target.value, id: event.target.id})];
			
			formdata.append(event.target.id, input);
			
		}
		//console.log(formdata);

	}

	handleSubmit = async (event) => {
		if(window.confirm("Olet poistamassa tuotteen järjestelmästä. Toimintoa ei voi peruuttaa. Haluatko jatkaa?")) {
		event.preventDefault();
		const url = "delete_products.php";


		const { data } = await axiosConfig.post(url, formdata, {
    	})
    	this.setState({response: data});
    	window.location.reload();
    
    	}
	}



	buildNotInUseButton(product) {
		let value = product.not_in_use;
		let type = product.type;
		if(localStorage.getItem('user') === "is_super" || localStorage.getItem('user') === "is_admin") {
		return (<input 
				type="button" 
				id={product.id}
				value={product.show_this === "1" ? "Piilota etusivulta":"Näytä etusivulla"} 
				onClick={(e) => this.handleNotInUse(e, product.show_this)}/>
			)
		}
	}

	buildCheckBox(product) {
		if(localStorage.getItem('user') === "is_super" || localStorage.getItem('user') === "is_admin") {
		
			return (
				<input 
				type="checkbox" 
				name={product.newsheader}
				id={product.id}
				value={product.type} 
				onChange={this.handleInputChange}/>
			)
		
		}
	}

	buildUpdateLink(product) {
		let osoite = "";
		if(typeof product.osoite !== "undefined") {
			osoite = product.osoite;
		}
		if(localStorage.getItem('user') === "is_super" || localStorage.getItem('user') === "is_admin") {
		
			return (
				<Link to="/update_news" 
					  state={{ 
					  	id: product.id,
					  	newsheader: product.newsheader, 
					  	imagefilename: product.imagefilename, 
					   	liftuptext: product.liftuptext, 
					  	newsbody: product.newsbody, 
					  	published_at: product.published_at, 
					  	contact_id: product.contact_id, 
					  	show_this: product.show_this,
					  	is_new: false
					  }}>Muuta tietoja</Link>
			)
		
	}}

		
	buildProductInfo(product) {
		
		return (
			
			<tr key={product.nimi}>
			<td>{product.newsheader}</td>
			<td className="hide-cell-mobile">{product.published_at}</td>
			
			
			<td>{this.buildUpdateLink(product)}</td>
			<td>
			{this.buildNotInUseButton(product)}
			</td>

			</tr>
			
			)
	
	}

	buildProductList(content) {
		if(content.length > 0) {
			return(
				content.map(product => this.buildProductInfo(product))
				)
		}
		else {
			return (
				<p>Tällä hetkellä ei ole tuotteita</p>
				)
		}	
	}

	buildAdminHeaders() {
		if(localStorage.getItem('user') === "is_super" || localStorage.getItem('user') === "is_admin") {
		
			return (
				<>
				<th>Muuta tietoja:</th>
				<th>Piilota etusivulta:</th>
				
				</>
			)
		
		}
	}

	render() {
		let content = this.state.content;
		if(content.length !== 0) {
			
			return (
			<div className="reservation-listing-container reservation-fonts">
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>
			<Link to="/update_news" className="return-link" state={{ 
					  	id: "",
					  	newsheader: "", 
					  	imagefilename: "", 
					   	liftuptext: "", 
					  	newsbody: "", 
					  	published_at: "", 
					  	contact_id: "", 
					  	show_this: "",
					  	is_new: true
					  }}>Luo uusi uutinen</Link>
			<form onSubmit={this.handleSubmit} className="reservation-data-table">
			<table>
			<tbody>
			<tr>
				
				<th>Otsikko:</th>
				<th className="hide-cell-mobile">Päivämäärä:</th>
				
				
				{this.buildAdminHeaders()}
			</tr>
      			{this.buildProductList(content)}
      		</tbody>
      		</table>
      		<br />
      		<input type="submit" value="Poista valitut" className="return-link"/>
      		</form>
			</div>
		);
		}
		else {
			return (
				<div className="reservation-listing-container">
				<Link to="/contentmanagement">Takaisin hallintapaneeliin</Link>
				<p>Tällä hetkellä ei ole kohteita</p>
				</div>
				)
		}

		
	}
}
		
export default ListNews;