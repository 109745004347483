import React from 'react';
import Footer from './frontend/footer';
import Links from './frontend/links';
import Meta from './frontend/meta';
import MaamiesRoutes from './frontend/routing/router';
import CookiesReminder from './frontend/cookiesreminder';

import './css/loader.css';
import './css/app.css';





class App extends React.Component {
 

 
  render() {
   
    
       return (
    <>
    <div className="container">
     
      <Meta />
      <div className="grid-header">
        <Links />
      </div>
      <div className="grid-main loader">
        <MaamiesRoutes />
      </div>
     </div>
     <div className="grid-footer">
        <CookiesReminder />
        <Footer />
      </div>
    </>
    );
    }
  }
  
export default App;
