import React from 'react';
import Liftups from './liftups';
import NewsLiftups from './newsliftups';
import '../css/maincontent.css';


class MainContent extends React.Component {

   prompt() {
    
    
  }

   
  render() {
    
   

  return (
    <div className="maincontent-liftup-grid">
      
      <div className="maincontent-grid-left maincontent-mobile-grid-top">
        <Liftups />
      </div>
      <div className="maincontent-grid-right maincontent-mobile-grid-bottom">
        <NewsLiftups />
      </div>
    </div>
    );

  }
}
  
export default MainContent;
