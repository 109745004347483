
import {Link} from 'react-router-dom';
import '../css/links.css';
import Header from './header.js';
import Login from './login.js';

function ContentManagement() {
	if(localStorage.getItem('user')) {
		return (
			<Link to="contentmanagement" className="link">Sisällönhallinta</Link>
		);
	}
}

function Links() {
  return (

	<div>

			<div className="linkbar">	
				<Header />
				<div className="linkbar-wrapper">
	  		
	  		<Link to="/news" className="link">Ajankohtaista</Link>
	  		
	  		<div className="dropdown">
	  			<Link to="" className="link">Yhdistys</Link>
	  				<div className="dropdown-content">
	  				 	<Link to="/description" className="link dropdown-link">Kuvaus</Link><br/> 
	  					<Link to="/galleria" className="link dropdown-link">Galleria</Link><br/>
	  					<Link to="/liity" className="link dropdown-link">Liity jäseneksi</Link><br/>
	  					<Link to="/theplan" className="link dropdown-link">Toimintasuunnitelma</Link><br/> 
	  					<Link to="/calendar" className="link dropdown-link">Kalenteri</Link><br/>
	  					<Link to="/contacts" className="link dropdown-link">Yhteystiedot</Link>
	  				</div>
	  		</div>

	  		<div className="dropdown">	  
      		<Link to="" className="link">Palvelut</Link>
						<div className="dropdown-content">
							<Link to="/machines" className="link dropdown-link">Vuokrattavat koneet</Link><br />
							<Link to="/vuokrattavat_tarvikkeet" className="link dropdown-link">Vuokrattavat tarvikkeet</Link><br />
							<Link to="/services" className="link dropdown-link">Jäsenten tarjoamat palvelut</Link>
						</div>
				</div>
				{/*
				<div className="dropdown">	  
      		<Link to="/kylayhdistys_kohteet" className="link">Teuro-Kuuslammin Kyläyhdistys</Link>
						<div className="dropdown-content">
							<Link to="/kylayhdistys_kohteet" className="link dropdown-link">Vuokrattavat kohteet</Link><br />
							
						</div>
				</div>
				*/}

					{ContentManagement()}
				
				</div>
				<div className="login-wrapper">
					<Login />
				</div>
				<div className="mobilelinks">
					<Link to="/mobilelinks" className="mobiletoggler">&#9776;</Link>
				</div>

			</div>


			
	</div>
	); 
}

export default Links;