import React, { useEffect, useState } from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import '../../../css/reservations.css';
import {useLocation, useNavigate, Link} from 'react-router-dom';
import {binaryInputToText, timeSlotsToTimes} from '../../helperFunctions';


const Reservation = () => {

	const navigate = useNavigate();
	const goBack = (e) => {
		e.preventDefault();
		navigate(-1);
	}
  
  const location = useLocation();
  const { res_ref, product } = location.state;
  const [reservation, setReservation] = useState([]);
 	
  
  
  	const getReservation = async () => {
  		
  		const url = "reservation.php"
  		const formData =  new FormData();
  		formData.append('res_ref', res_ref);
			const { data } = await axiosConfig.post(url, formData, {});
  		setReservation(data);
  		//console.log(data);
  	};


	useEffect(() => {
	
    	if(Object.keys(reservation).length === 0) {
    		getReservation();
    	}
    	
    	
    	
  	});

	const buildLiftups = () => {
		if(reservation.length > 0) {
		return (
			<div className="res-invoice-page" >
			<div className="update-reservation-info-link-div">
			<Link to="" onClick={(e) => goBack(e)}  className="return-link">Takaisin varauksiin</Link>
					<Link className="update-reservation-info-link"
									to={{ pathname: '/modify_reservation/' + res_ref  }} 
									state={{reservationList: reservation[4],
													customer_id: reservation[1].id, 
													id: reservation[2].id,
													type: reservation[2].type, 
													name: product,
													firstname: reservation[1].first_name,
													lastname: reservation[1].last_name,
													phone: reservation[1].phone,
													email: reservation[1].email,
													address: reservation[1].address,
													postal_code: reservation[1].postal_code,
													city: reservation[1].city }}>Täydennä varauksen tietoja</Link>
													</div>
					<div>
						
						<h3>Varausnumero: {res_ref}</h3>
						<h3>Tuote: {product}</h3>
						<b>Hintatiedot:</b><br />
						<p>{reservation[2].hintaperuste} {reservation[2].hinta}</p>
						<p>{reservation[2].hintaperuste2} {reservation[2].hinta2}</p>
						<br />
						<p>Lasku lähetetty: {binaryInputToText(reservation[0].invoice_sent)}</p>
						<p>Varaus hyväksytty: {binaryInputToText(reservation[0].validated)}</p>
						<br />
						<br />
						<h3>Yhdistyksen tiedot:</h3>
						<p><b>Tilinumero:</b> {reservation[5].tilinumero}</p>
						<p><b>Y-tunnus:</b> {reservation[5].ytunnus}</p>
						<br />
						<br />
						<h3>Kohteen haltija:</h3>
						<p>{reservation[3].etunimi} {reservation[3].sukunimi}</p>
						<p>{reservation[3].puhelin}</p>
						<p>{reservation[3].email}</p>
						<br />
						<br />
						<h3>Asiakas:</h3>
						<p>{reservation[1].first_name} {reservation[1].last_name}</p>
						<p>{reservation[1].phone} {reservation[1].email}</p>
						<p>{reservation[1].address} {reservation[1].postal_code} {reservation[1].city}</p>
					
					
					<br />
					<div>
						<h3>Varatut ajat:</h3>
						{reservation[4].map(reservation => timeSlotsToTimes(reservation))}</div>
					</div>
					<br />
					
			</div>


			
		);
	}
	else {
		return (<div>Jotain meni pieleen</div>)
	}
	};


  return (
    

    <div className="machine-liftups-container">
    
      	{buildLiftups()}
    
	</div>
   
  );
};

export default Reservation;