import React, { useState, useEffect } from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import '../../../css/reservations.css';
import Calendar from 'react-calendar';
import Time from '../../calendar/time';
import {formatDate, timeSlotsToTimes} from '../../helperFunctions';
import {useParams, useLocation, Link, useNavigate} from 'react-router-dom';



function ModifyReservation() {
	const navigate = useNavigate();
	const goBack = (e) => {
		e.preventDefault();
		navigate(-1);
	}


	const location = useLocation();
	const { name, type, id, customer_id, reservationList, firstname, lastname, phone, email, address, postal_code, city } = location.state;
	const { res_ref } = useParams();
	const [alreadyReserved, setAlreadyReserved] = useState([]);
	const [thisDate, setDate] = useState(new Date());
	const [showTime, setShowTime] = useState(false);
	const [reservations, setReservations] = useState([]);
	const [times, setTimes] = useState();
	const [reservation, setReservation] = useState([]);
	const [contactInfo, setContactInfo] = useState({
    	firstname: firstname,
    	lastname: lastname,
    	email: email,
    	phone: phone,
    	address: address,
    	postoffice: city,
    	postal: postal_code,

  	});

	//console.log(reservationList);
	/*
	function tileClassName({ date, view }) {
		let className = 'white-all-free'; 
		for(let i = 0; i < Object.keys(reservations).length; i++) {
			
		
			if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use !== '1') {
				className = 'yellow-partial-reserved';

			}
			else if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use === '1') {
				className = 'grey-out-of-order';
			}
			if(reservations[i].res_date === formatDate(date) 
				&& reservations[i].times.length === 6) {
				className = 'red-all-reserved';
			}
			
		}
		return className;
		
  	}
  	*/

  	function tileClassName({ date, view }) {
  		let className = 'white-all-free';
		let timescounter = 0;
		for(let i = 0; i < Object.keys(reservations).length; i++) {

			if(reservations[i].res_date === formatDate(date)) {
				timescounter = timescounter + reservations[i].times.length;
			}


			if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use !== '1' && (timescounter > 0 || reservations[i].times.length > 0)) {
				className = 'yellow-partial-reserved';

			}
			else if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use === '1') {
				className = 'grey-out-of-order';
			}
			if((reservations[i].res_date === formatDate(date) 
				&& reservations[i].times.length === 6) || timescounter === 6) {
				className = 'red-all-reserved';
			}
			
		}
		for(let j = 0; j < Object.keys(reservation).length; j++) {
			
			if(reservation[j].get("Date") === formatDate(date)) {
				className = "green-chosen-time";
			} 
			
		}
		const thisdate = new Date();
		let newDate = formatDate(thisdate);
		

		if(formatDate(date) < newDate) {
			className = "grey-out-of-order";
		}

		//console.log(timescounter);
		return className;
  		/*
		let className = 'white-all-free';
		let timescounter = 0; 
		for(let i = 0; i < Object.keys(reservations).length; i++) {
			if(reservations[i].res_date === formatDate(date)) {
				timescounter = timescounter + reservations[i].times.length;
			}


			if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use !== '1' && (timescounter > 0 || reservations[i].times.length > 0)) {
				className = 'yellow-partial-reserved';

			}
			else if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use === '1') {
				className = 'grey-out-of-order';
			}
			if((reservations[i].res_date === formatDate(date) 
				&& reservations[i].times.length === 6) || timescounter === 6) {
				className = 'red-all-reserved';
			}
			
		}
		return className;
		*/
		
  	}

  
  	function buildPreviousReservation(reservationList) {
  		let array = [...reservation];

  		if(typeof reservationList !== "undefined") {
  			reservationList.forEach(reservation => {
  				
  				let form = new FormData();
  				form.append("Date", reservation[0]);
  				for(let i = 1; i < reservation.length; i++) {
  					form.append(reservation[i].timeslot, reservation[i].timeslot);

  				}
  			
  				array.push(form);
  				console.log(array);
  			});
  		}
  		setAlreadyReserved(array);
  		

  	}

  	const renderAlreadyReserved = (reservationList) => {
  		return (
  			reservationList.map(times =>
  				<button>
  				<div key={times[0]}>
  				<p>Päivämäärä: {times[0]}</p>
  				<p>Valitut kellonajat: </p>
  				<p>{times[1].timeslot}</p>
  				<p>{times[2].timeslot}</p>
  				<p>{times[3].timeslot}</p>
  				<p>{times[4].timeslot}</p>
  				<p>{times[5].timeslot}</p>
  				<p>{times[6].timeslot}</p>
				</div>
				</button>

  			)
  		);
  	}
  	

  	function tileDisabled({ date, view }) {
  		for(let i = 0; i < Object.keys(reservations).length; i++) {
			if(reservations[i].res_date === formatDate(date) 
			&& reservations[i].not_in_use === '1'
			) 	{
			return true;
			}
			if(reservations[i].res_date === formatDate(date) 
				&& reservations[i].times.length === 6) {
				return true;
			} 
		}	
  		
  	}
  
 	/*
  	const onDateChange = (newDate) => {
  		setShowTime(true);
		setDate(newDate);


		let returnvalue = [];
  		for(let i = 0; i < Object.keys(reservations).length; i++) {
  			
			if(reservations[i].res_date === formatDate(newDate) && Object.keys(reservations[i].times).length > 0) {
				returnvalue = reservations[i].times.map(time => time);
				setTimes(returnvalue);

			}
			else {
				setTimes(returnvalue);
			}
		}
	}
	*/

	const onDateChange = (newDate) => {
  		setShowTime(true);
		setDate(newDate);


		let returnvalue = [];
  		for(let i = 0; i < Object.keys(reservations).length; i++) {
  			
			if(reservations[i].res_date === formatDate(newDate) && Object.keys(reservations[i].times).length > 0) {
				returnvalue = returnvalue.concat(reservations[i].times.map(time => time));
				//setTimes(returnvalue);

			}
			else {
				//setTimes(returnvalue);
			}
		}
		setTimes(returnvalue);
	}
 



 
 	const handleChange = (event) => {
   		setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  	};
  	

  	

	const getReservations = async () => {
  		const url = "check_reservations.php"
  		const formData =  new FormData();
  		formData.append('id', id);
  		formData.append('type', type);
  		const { data } = await axiosConfig.post(url, formData, {
    	})
    	//console.log(data);
    	setReservations(data);
    	
	};

	const updateReservation = async (e) => {
		if(window.confirm("Olet päivittämässä varauksen tietoja. Haluatko jatkaa?")) {
		const formData = new FormData();
  		e.preventDefault();
  		const url = "update_reservation.php";
  		formData.append("id", id);
  		formData.append("type", type);
  		formData.append("customer_id", customer_id);
  		formData.append("res_ref", res_ref);

  		Object.keys(contactInfo).forEach(key => {
  			formData.append(key, contactInfo[key]);
  		} 
  		);

  		//console.log(reservation);
  		
  		Object.keys(reservation).forEach(key => {
  			let array = [];
  			for(const pair of reservation[key].entries()) {
  				
  				if(pair[0] === "Date") {
  					array.push(pair[1]);
  				}
  				else {
  					array.push(pair[0]);
  				}
  				
  				
  			}
  		
  			var json_arr = JSON.stringify(array);
			formData.append(key, json_arr);
  		});

  		

		//console.log(formData);
		const { data } = await axiosConfig.post(url, formData, {});
		
		//console.log(data);
		window.location.replace("/show_reservations/");
		  	
	}
  	}

	useEffect(() => {
		if(Object.keys(reservations).length === 0) {
    		getReservations();
    		
    	}
    	
    	
    	if(typeof reservationList !== "undefined" && reservation.length === 0) {
    		buildPreviousReservation(reservationList);
    	}
    	
    	
    	setDate(thisDate);
    	//setReservation(reservation);
    	
  	}, [thisDate, reservation]);
	
	

	
	
	return (
	<div className="res-invoice-page">
		<div className="reservation-form">
		<Link to="" onClick={(e) => goBack(e)}  className="return-link">Takaisin varauksiin</Link>
		<h1>Varauksen päivittäminen</h1>
			<details>
				<summary className="spoiler-text">Aiemmin varatut ajat</summary>
				<div className="chosen-times">{reservationList.map(reservation => timeSlotsToTimes(reservation))}</div>
				<br />
				<br />
				<br />
			</details>
			<details value="Varaajan tiedot">
				<summary className="spoiler-text">Varaajan tiedot</summary>
				<form onChange={handleChange}>
				<label>Etunimi</label><br />
				<input type="text"  className="management-input" name="firstname"  defaultValue={contactInfo.firstname} required></input>
				<br />				
				<label>Sukunimi</label><br />
				<input type="text" className="management-input" name="lastname" defaultValue={contactInfo.lastname} required></input>
				<br />
				<label>Puhelin</label><br />
				<input type="number" className="management-input" name="phone" defaultValue={contactInfo.phone}></input>
				<br />
				<label>Email</label><br />
				<input type="email" className="management-input" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" defaultValue={contactInfo.email}></input>
				<br />
				<label>Katuosoite</label><br />
				<input type="text" className="management-input" name="address" defaultValue={contactInfo.address}></input>
				<br />
				<label>Postitoimipaikka</label><br />
				<input type="text" className="management-input" name="postoffice" defaultValue={contactInfo.postoffice}></input>
				<br />
				<label>Postinumero</label><br />
				<input type="number" className="return-link" name="postal" defaultValue={contactInfo.postal}></input>
				<br />
				<br />
				<br />
				

				</form>
			</details>
		</div>
    	<div className="reservation-form">
    		<details>
    		<summary className="spoiler-text">Lisää aikoja varaukseen</summary>
			<h1>Varauskalenteri: {name}</h1>
      		<Calendar 
      		onChange={onDateChange} 
      		value={thisDate} 
      		tileClassName={tileClassName} 
      		tileDisabled={tileDisabled} />
      		<Time showTime={showTime} date={thisDate} times={times} setReservation={setReservation} reservation={reservation} />
      		</details>

      		<br />
      		<br />
      		<button onClick={updateReservation} className="submit-button">Päivitä varaus</button>
    	</div>
    	
       	
    	
    </div>

  );
	

}

export default ModifyReservation;