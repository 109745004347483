import {React, useState, useEffect, useRef} from 'react';
import axiosConfig from '../../backend/axios/axiosConfig';
import {Link, useLocation} from 'react-router-dom';
//import '../../../css/liftup.css';
import '../../css/reservations.css';


function UpdateNewsForm() { 
	const location = useLocation();
	const { newsheader, id, imagefilename, newsbody, published_at, show_this, liftuptext, is_new } = location.state;
	const [selectedFile, setSelectedFile] = useState(null);
  	const [imagePreview, setImagePreview] = useState(imagefilename);
  	
  	const [response, setResponse] = useState(""); 

  	
  	


  	const [productInfo, setproductInfo] = useState({
  		id: id,
  		newsheader: newsheader, 
  		imagefilename: imagefilename, 
  		newsbody: newsbody, 
  		published_at: published_at, 
  		show_this: show_this, 
  		liftuptext: liftuptext
  	})


  	
  	
  	const sendUpdateProduct = async (e) => {
  		if(window.confirm("Olet päivittämässä tuotteen tietoja. Haluatko jatkaa?")) {
  		e.preventDefault();
  		
  		let url = "";
  		if(is_new === true) {
  			url = "create_news_item.php";
  		}
  		else {
  			url = "update_news_data.php";
  		}
  		let formData = new FormData();
  		
  		
		if(typeof selectedFile !== null) {
  			formData.append('image', selectedFile);

  		}

		Object.keys(productInfo).forEach(key => {
			formData.append(key, productInfo[key]);
		});
		
		

		
		const { data } = await axiosConfig.post(url, formData, { headers: {
      	'Content-Type': 'multipart/form-data'
    	}});
		console.log(data);
		}
		
		window.location.replace("/list_news");
  	}


  	


  	

  	
  	

  	


  	const handleProductChange = (event) => {
    	setproductInfo({ ...productInfo, [event.target.name]: event.target.value });
  	};

  	const handleImageChange = (event) => {
  		setSelectedFile(event.target.files[0]);
  		setImagePreview(URL.createObjectURL(event.target.files[0]));
  		//console.log(event.target.files[0]);
  	}
  	const header = () => {
			if(is_new === true) {
				return (<h1>Julkaise uusi uutinen</h1>);
			}
			else {
				return (<h1>Päivitä uutisen sisältöä</h1>);
			}
		}
  	
  	
	
	function buildReservationForm(props) {

		


		return (
			<div className="reservation-fonts">
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>

			{header()}
			<div className="reservation-form">
				<form onSubmit={sendUpdateProduct}>
				<br />
				{response}
				<br />
				<br />
				
				<label>Otsikko:</label><br />
				<input className="management-input" type="text" name="newsheader"  value={productInfo.newsheader} onChange={handleProductChange} required></input>
				<br />
				<label>Etusivulla näkyvä lyhyt teksti:</label><br />
				<textarea className="management-input" maxLength="150" type="text" name="liftuptext" value={productInfo.liftuptext} onChange={handleProductChange} required></textarea>
				<br />
				<label>Ajankohtaista -sivulla näkyvä pitkä teksti:</label><br />
				<textarea className="management-input" type="text" name="newsbody" value={productInfo.newsbody} onChange={handleProductChange} required></textarea>
				<br />
				
				<br />
				<label>Nykyinen kuvatiedosto (ei pakollinen):</label><br />
				<img src={imagePreview} className="machine-liftup-image"/><br />
				<br />
				<label>Vaihda kuvatiedosto (ei pakollinen):</label><br />
				<input className="management-input" type="file" onChange={handleImageChange}/>
				<br />
				<br />
				
				

				<input type="submit" value={is_new ? "Julkaise uutinen":"Päivitä uutinen"} className="return-link"></input>

				</form>
			</div>
			</div>
		);
		}
		
	
		return (
		<div className="yhdistys-container">
			<div className="yhdistys-wrapper">
      			{buildReservationForm()}
			</div>
		</div>		
		);
	
}
		
export default UpdateNewsForm;