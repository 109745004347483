import React, { useEffect, useState } from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import '../../../css/reservations.css';
import {Link} from 'react-router-dom';
import {useLocation, useNavigate} from 'react-router-dom';
import {timeSlotsToTimes, binaryInputToText} from '../../helperFunctions';


const Invoice = () => {

	const navigate = useNavigate();
	const goBack = (e) => {
		e.preventDefault();
		navigate(-1);
	}
  
  const location = useLocation();
  const { res_ref, invoice_to_be_paid, invoice_paid } = location.state;
  const [invoice, setInvoice] = useState([]);
 	//console.log(invoice_to_be_paid);
  
  	const getInvoice = async () => {
  		
  		const url = "invoice.php"
  		const formData =  new FormData();
  		formData.append('res_ref', res_ref);
			const { data } = await axiosConfig.post(url, formData, {});
  		setInvoice(data);
  		//console.log(data);
  	};


  	const resendInvoice = async () => {
  		if(window.confirm("Olet lähettämässä laskun uudelleen. Oletko varma?")) {
  		const url = "resend_invoice.php"
  		const formData =  new FormData();
  		formData.append('res_ref', res_ref);
			const { data } = await axiosConfig.post(url, formData, {});
			}
  	}

  	const buildReservationTimes = (res) => {
  		if(res.error) {
  			return (
  				<p>Ei varattuja aikoja</p>
  			);
  		}
  		else {
  			return (
  				res.map(reservation => timeSlotsToTimes(reservation))
  			)
  		}
  	}

	useEffect(() => {
	
    	if(Object.keys(invoice).length === 0) {
    		getInvoice();
    	}
    	
    	
    	
  	});

	
	const buildLiftups = () => {
		if(invoice.length > 0) {
		return (
			<div className="res-invoice-page" >
					<div>
						<button onClick={resendInvoice}>Lähetä lasku uudelleen</button>
						<br />
						<br />
						<b>Varausnumero:</b> <br />{res_ref} <br />
						<br /> 
						<b>Tuote:</b> <br /> {invoice[0].nimi} <br /> 
						<br />
						<b>Eräpäivä:</b> <br />{invoice_to_be_paid} <br />
						<br />
						<b>Maksettu:</b> {binaryInputToText(invoice_paid)} <br />
						<br />
						<b>Hintatiedot:</b><br />
						<p>{invoice[0].hintaperuste} {invoice[0].hinta}</p>
						<p>{invoice[0].hintaperuste2} {invoice[0].hinta2}</p>
						<br />
						<h3>Kohteen haltija:</h3>
						<p>{invoice[1].etunimi} {invoice[1].sukunimi}</p>
						<p>{invoice[1].puhelin}</p>
						<p>{invoice[1].email}</p>
						<br />
						<h3>Yhdistyksen tiedot:</h3>
						<p><b>Tilinumero:</b> {invoice[4].tilinumero}</p>
						<p><b>Y-tunnus:</b> {invoice[4].ytunnus}</p>
						<br />
						<h3>Asiakas:</h3>
						<p>{invoice[2].first_name} {invoice[2].last_name}</p>
						<p>{invoice[2].phone} {invoice[2].email}</p>
						<p>{invoice[2].address} {invoice[2].postal_code} {invoice[2].city}</p>
						<br />
						<br /> 
						<div>
						<h3>Varatut ajat:</h3>
						{buildReservationTimes(invoice[3])}</div>
					</div>

			</div>

			
		);
	}
	else {
		return (<div>Jotain meni pieleen</div>)
	}
	};


  return (
    

    <div className="machine-liftups-container">
    <Link to="" onClick={(e) => goBack(e)} className="return-link">Takaisin laskuihin</Link>
      	{buildLiftups()}
	</div>
   
  );
};

export default Invoice;