import {React, useState, useEffect, useRef} from 'react';
import axiosConfig from '../../backend/axios/axiosConfig';
import {Link, useLocation} from 'react-router-dom';
import '../../css/liftup.css';


function UpdatePassword() { 
	

  	const [contactInfo, setContactInfo] = useState({
  		new_password: "",
    	new_password_again: "",
    	

  	});
  	
  	
  	const [password_match, setPassWordMatch] = useState("");
  	const [response, setResponse] = useState(""); 

  	
  	
  	const sendUpdateContact = async (e) => {
  		
  		if(validatePassword()) {
  			if(window.confirm("Olet vaihtamassa salasanan. Haluatko varmasti jatkaa?")) {
  		e.preventDefault();
  		

  		const url = "update_password.php";
  		let formData = new FormData();
  		
  	
		formData.append('username', localStorage.getItem('username'));
		formData.append('id', localStorage.getItem('id'));
		formData.append('new_password', contactInfo['new_password']);

		/*
		Object.keys(contactInfo).forEach(key => {
			formData.append(key, contactInfo[key]);
		});
		*/

		
  		

		//console.log(formData);
		const { data } = await axiosConfig.post(url, formData);
			window.confirm("Salasana on vaihdettu onnistuneesti! Uusi salasana on: " + contactInfo['new_password'] + " Kun klikkaat OK, siirryt sivuston etusivulle.");
			window.location.replace("/");
		}
  		}
  		else {
  			window.confirm("Salasanat eivät täsmää")
  		}
  		
		
  	}

  	const validatePassword = () => {
  		if(contactInfo['new_password'] === contactInfo['new_password_again']) {
  				return true;
  				
  			}
  			else {
  				
  				return false;
  				
  				
  			}
  	}
  

	
  	
  
  	const handleProductChange = (event) => {
    	setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
    		
  		
  	};
  	
	
	function buildReservationForm(props) {
		

		return (
			<div className="reservation-fonts">
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>

			<h1>Vaihda salasana:</h1>
			<div className="reservation-form">
				<form onSubmit={sendUpdateContact}>
				<br />
				
				<br />
				<br />
				<br /> 
				<br />
				<label>Uusi salasana:</label><br />
				<input className="management-input" type="text" name="new_password"  value={contactInfo.new_password} onChange={handleProductChange}></input>
				<br />
				<br />
				<label>Uusi salasana toisen kerran:</label><br />
				<input className="management-input" type="text" name="new_password_again" value={contactInfo.new_password_again} onChange={handleProductChange}></input>
				<br />
				
    		
    		<br />
    		<br />
    		
    		
				<input type="submit" className="return-link" value="Hyväksy salasana"></input>

				</form>
			</div>
			</div>
		);
		}
		
	
		return (
		<div className="yhdistys-container">
			<div className="yhdistys-wrapper">
      			{buildReservationForm()}
			</div>
		</div>		
		);
	
}
		
export default UpdatePassword;