import React from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import '../../../css/reservations.css';
import {Link} from 'react-router-dom';


const formdata = new FormData();
const formdata2 = new FormData();

class ListContacts extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
			response: '',
		}


	};

	 

	componentDidMount() {
		const url = "contact_query.php"
		formdata2.append("creds", localStorage.getItem("user"));
		formdata2.append("type", localStorage.getItem("user_type"));
		axiosConfig.post(url, formdata2).then(response => response.data).then((data) => {
      this.setState({ content: data })
      //console.log(this.state.content);
     })
	}

	


	handleInputChange = (event, type) => {

		const target = event.target;
		const id = target.id;
		

		if(formdata.get(id) === type) {
			formdata.delete(id);
		}
		else {
			formdata.append(id, type);
			
		}
		//console.log(formdata);

	}

	handleSubmit = async (event) => {
		if(window.confirm("Olet poistamassa yhteystiedot järjestelmästä. Toimintoa ei voi peruuttaa. Oletko varma?")) {
		event.preventDefault();
		const url = "delete_contacts.php";


		const { data } = await axiosConfig.post(url, formdata, {
    	})

    	
    	this.setState({response: data});
    	window.location.reload();
    	

		}
	}

	buildCheckBox(contact) {
		if(!contact.contact_exists) {
			return (
				<input 
				type="checkbox" 
				id={contact.id}
				onChange={(e) => this.handleInputChange(e, contact.type)}/>
				)
		}
	}

		
	buildUserInfo(contact) {
		let type = contact.type;
		let fullname = contact.etunimi+" "+contact.sukunimi;

		return (
			<>
			<tr key={contact.id}>
			<td>{contact.etunimi} {contact.sukunimi}</td>
			<td>{contact.puhelin}</td>
			<td>{contact.email}</td>
			<td>{contact.rooli}</td>
			<td><Link to="/update_contact_data" 
					  state={{ id: contact.id,
					  		   etunimi: contact.etunimi, 
					  		   sukunimi: contact.sukunimi, 
					  		   puhelin: contact.puhelin, 
					  		   email: contact.email, 
					  		   rooli: contact.rooli,
					  		   address: contact.address,
					  		   city: contact.city,
					  		   postal_code: contact.postal_code,
					  		   type: contact.type  }} >Muuta tietoja</Link></td>
			<td>{this.buildCheckBox(contact)}</td>
			</tr>
			</>
			)
	
	}

	buildUserList(content) {
		if(content.length > 0) {
			return(
				content.map(user => this.buildUserInfo(user))
				)
		}
		else {
			return (
				<p>Tällä hetkellä ei ole varauksia</p>
				)
		}	
	}

	render() {
		let content = this.state.content;
		if(content.length !== 0) {
			
			return (
			<div className="reservation-listing-container reservation-fonts">
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>
			<form onSubmit={this.handleSubmit}>
			<table>
			<tbody>
			<tr>
				
				<th>Nimi:</th>
				<th>Puhelin:</th>
				<th>Email:</th>
				<th>Rooli:</th>
				<th>Muuta tietoja:</th>
				<th>Poista:</th>
			</tr>
      			{this.buildUserList(content)}
      		</tbody>
      		</table>
      		<br />
      		<input type="submit" value="Poista valitut" className="return-link"/>
      		</form>
			</div>
		);
		}
		else {
			return (
				<div className="reservation-listing-container">
				<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>
				<p>Tällä hetkellä ei ole yhteystietoja</p>
				</div>
				)
		}

		
	}
}
		
export default ListContacts;