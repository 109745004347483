import {useNavigate} from 'react-router-dom';

const buildContact = (contact) => {
  		return (
  		<div key={contact.id}>
			<p>Puhelin: {contact.puhelin}</p>
  			<p>Email: {contact.email}</p>
  		</div>
  		);
};

const formatDate = (date) => {
		var datestring = date.getFullYear() + "-" + ("0" + (date.getMonth()+1)).slice(-2) + "-" + ("0"+date.getDate()).slice(-2); 
		return datestring;
		

}

const formatDateToReadable = (date) => {
		var datestring = date.getDate() 
						+ "." + ("0" + (date.getMonth()+1)).slice(-2) 
						+ "." + date.getFullYear(); 
		return datestring;
		

}

const binaryInputToText = (input) => {
			if(input === "0") {
				return <p>Ei</p>;
			}
			else {
				return <p>Kyllä</p>
			}

	}

function timeSlotsToTimes(reservation_array) {
	let date = reservation_array[0];
	let time1;
	let time2;
	let time3;
	let time4;
	let time5;
	let time6;

	if(Object.keys(reservation_array).length === 7) {
		return (<div key={date}>
				<p>Päivämäärä: {date}</p>
				<p>Varatut kellonajat: </p>
				<p>08:00-20:00 </p>
				</div>);
	}
	else {
		for(let i = 0; i < Object.keys(reservation_array).length; i++) {
			console.log(reservation_array[i]);
			if(i === 0) {
				date = formatDateToReadable(new Date(reservation_array[0]));
			}
			if(i === 1) {
				time1 = convertTimeSlot(reservation_array[1].timeslot);
			}
			if(i === 2) {
				time2 = convertTimeSlot(reservation_array[2].timeslot);
			}
			if(i === 3) {
				time3 = convertTimeSlot(reservation_array[3].timeslot);
			}
			if(i === 4) {
				time4 = convertTimeSlot(reservation_array[4].timeslot);
			}
			if(i === 5) {
				time5 = convertTimeSlot(reservation_array[5].timeslot);
			}
			if(i === 6) {
				time6 = convertTimeSlot(reservation_array[6].timeslot);
			}
			

		}
		return (<div key={date}>
				<p>Päivämäärä: {date}</p>
				<p>Varatut kellonajat: </p>
				<p>{time1}</p>
				<p>{time2}</p>
				<p>{time3}</p>
				<p>{time4}</p>
				<p>{time5}</p>
				<p>{time6}</p>
				</div>);
	}
	
}

const convertTimeSlot = (timeslot) => {
	let return_time;
	if(timeslot === "1") {
		return_time = "08:00-10:00";
	}
	if(timeslot === "2") {
		return_time = "10:00-12:00";
	}
	if(timeslot === "3") {
		return_time = "12:00-14:00";
	}
	if(timeslot === "4") {
		return_time = "14:00-16:00";
	}
	if(timeslot === "5") {
		return_time = "16:00-18:00";
	}
	if(timeslot === "6") {
		return_time = "18:00-20:00";
	}
	return return_time;
}


function GoBack() {
	const navigate = useNavigate();
	
	const goBack = () => {
		navigate(-1);
	}
	

}

function tileClassName({ date, view, reservations }) {
		let className = 'white-all-free'; 
		for(let i = 0; i < Object.keys(reservations).length; i++) {
			
		
			if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use !== '1') {
				className = 'yellow-partial-reserved';

			}
			else if(reservations[i].res_date === formatDate(date) && reservations[i].not_in_use === '1') {
				className = 'grey-out-of-order';
			}
			if(reservations[i].res_date === formatDate(date) 
				&& reservations[i].times.length === 6) {
				className = 'red-all-reserved';
			}
			
		}
		return className;
		
  	}

 const helpButton = (props) => {
 		
  		return (
  			<button className="help-button" onClick={props}/>
  			
  			)
  		
 }


export {tileClassName, helpButton, buildContact, formatDate, formatDateToReadable, binaryInputToText, GoBack, timeSlotsToTimes};