import React from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import '../../../css/reservations.css';
import {Link} from 'react-router-dom';


const formdata = new FormData();
const formdata2 = new FormData();

class DeleteUsers extends React.Component { 

	constructor(props) {
		super(props);
		this.state = {
			content: [],
			response: '',
		}


	};

	 

	componentDidMount() {
		const url = "query_users.php"
		formdata2.append("creds", localStorage.getItem("user"));
		formdata2.append("type", localStorage.getItem("user_type"));
		axiosConfig.post(url, formdata2).then(response => response.data).then((data) => {
      this.setState({ content: data })
      //console.log(this.state.content);
     })
	}

	convertType(input) {
		let type;

		if(input === "0") {
			type = "Maa- ja kotitalousseura";
		}
		if(input === "1") {
			type = "Kotitalousnaiset";
		}
		if(input === "2") {
			type = "Kyläyhdistys";
		}
		return <div>{type}</div>;
	}

	convertCreds(is_admin, is_super, is_updater) {
		let creds;

		if(is_admin === "1") {
			creds = "Admin";
		}
		if(is_super === "1") {
			creds = "Pääkäyttäjä";
		}
		if(is_updater === "1") {
			creds = "Päivittäjä";
		}

		return <div>{creds}</div>;
	}


	handleInputChange = (event) => {

		const target = event.target;
		const username = target.name;
		const id = target.id;
		const type = target.usertype;

		if(formdata.get(username)) {
			formdata.delete(username);
		}
		else {
			formdata.append(username, username);
			
		}
		//console.log(formdata);

	}

	handleSubmit = async (event) => {
		if(window.confirm("Olet poistamassa käyttäjän järjestelmästä. Toimintoa ei voi peruuttaa. Oletko varma?")) {
		event.preventDefault();
		const url = "delete_users.php";


		const { data } = await axiosConfig.post(url, formdata, {
    	})
    	this.setState({response: data});
    	window.location.reload();
    
    	}
	}

		
	buildUserInfo(user) {
		return (
			<>
			<tr>
			<td className="hide-cell-mobile">{user.id}</td>
			<td>{user.username}</td>
			<td>{user.password}</td>
			<td className="hide-cell-mobile">{this.convertCreds(user.is_admin, user.is_super, user.is_updater)}</td>
			<td className="hide-cell-mobile">{this.convertType(user.type)}</td>
			<td><input 
				type="checkbox" 
				name={user.username}
				id={user.id}
				usertype={user.type} 
				onChange={this.handleInputChange}/></td>
			</tr>
			</>
			)
	
	}

	buildUserList(content) {
		if(content.length > 0) {
			return(
				content.map(user => this.buildUserInfo(user))
				)
		}
		else {
			return (
				<p>Tällä hetkellä ei ole varauksia</p>
				)
		}	
	}

	render() {
		let content = this.state.content;
		if(content.length !== 0) {
			
			return (
			<div className="reservation-listing-container reservation-fonts">
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>
			<form onSubmit={this.handleSubmit}>
			<table>
			<tbody>
			<tr>
				<th className="hide-cell-mobile">Id:</th>
				<th>Käyttäjätunnus:</th>
				<th>Salasana:</th>
				<th className="hide-cell-mobile">Käyttöoikeudet:</th>
				<th className="hide-cell-mobile">Käyttäjäryhmä:</th>
				<th>Poista:</th>
			</tr>
      			{this.buildUserList(content)}
      		</tbody>
      		</table>
      		<br />
      		<input type="submit" value="Poista valitut" className="return-link"/>
      		</form>
			</div>
		);
		}
		else {
			return (
				<div className="reservation-listing-container">
				<Link to="/contentmanagement">Takaisin hallintapaneeliin</Link>
				<p>Tällä hetkellä ei ole varauksia</p>
				</div>
				)
		}

		
	}
}
		
export default DeleteUsers;