import React from 'react';
import {Helmet} from 'react-helmet';

function Meta() {
  return (
		<Helmet>
			<title>Pohjois-Tammelan maa- ja kotitalousseura ry</title>
 
			<meta http-equiv="Content-Type" content="text/html" /> 
			<meta name="keywords" content="ptmks, ptmks.fi, maatalous, maanviljely, Teuro, Kuuslammi, Patakangas, Lautaporras,
			, Susikas, Pohjois-Tammela, maa- ja kotitalousseura, Pohjois-Tammelan maa- ja kotitalousseura, maamiesseura, kylä, kahvikalusto, kahvinkeitin,
			 vuokrakone, kesantosilppuri, konevuokraus, maa- ja kotitalousnaiset, Palax Power 70s, juontokoura, 
			 Vädestad 350, kuivalannan levitin, jankkuri, Väderstad Cultus 300, kultivaattori, imupainelietevaunu, 
			 lietteen sekoitin, vesakkomurskain, Kronos, hydraulihalkoja, hydraulihalkoja omalla hydrauliikalla, 
			 Myyrä oja-aura, oja-aura, pensasleikkuri" />
			<meta name="description" content="Pohjois-Tammelan maa- ja kotitalousseura" />
		</Helmet>
	
	);
}
	
export default Meta;