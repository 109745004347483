import React, { useEffect, useState } from 'react';
import axiosConfig from '../backend/axios/axiosConfig';
import '../css/liftup.css';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {buildContact} from './helperFunctions';


const Service = () => {
	const location = useLocation();
  const { id, name, etunimi, sukunimi, contact_id, linkki, muuta } = location.state;
  const [contact, setContact] = useState([]);
 
  /*
  	const getService = async () => {
  		const url = "service.php"
  		const formData =  new FormData();
  		formData.append('id', id);
  		const { data } = await axiosConfig.post(url, formData, {
    	})
    	setService(data);
	};
	*/

  	const getContact = async () => {
  		const url = "service_contact.php"
  		const formData =  new FormData();
  		formData.append('id', contact_id);
  		formData.append('etunimi', etunimi);
  		formData.append('sukunimi', sukunimi);
  		//console.log(formData);
			const { data } = await axiosConfig.post(url, formData, {});
  		setContact(data);
  	
  	}

	useEffect(() => {
			if(Object.keys(contact).length === 0) {
    		getContact();
    	}
    	
    });

	const buildLiftups = () => {
		
		return (
			<div className="liftup-wrapper" key={id}>
				<div className="service-liftup service-text">
					<div className="service-info">
					<h3 className="service-header">{name}</h3>
					<a rel="noreferrer" target="_blank" href={linkki} className="service-link">{linkki}</a>
					<p>{etunimi} {sukunimi}</p>
					<p>{muuta}</p>
					<div>{contact.map(contact => buildContact(contact))}</div>
					
					</div>
				</div>
			</div>
		
		);
	};


  return (
    

    <div className="service-liftups-container">
    <Link to="/services" className="service-liftup-return">Takaisin palveluihin</Link>
      	{buildLiftups()}
	</div>
   
  );
};

export default Service;